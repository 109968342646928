import React, { useState } from "react";
import { Form, TimePicker, Input, Checkbox, Select, InputNumber, Tooltip, Empty } from "antd";
import { SchemaClassifiersFieldProperty } from 'components';
import { Segment, Message } from 'semantic-ui-react'
import { InfoCircleTwoTone } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import timeFormats from 'common/data/timeFormats';
import CypressTestIds from "cypress/CypressTestIds";

const { Option } = Select;
const { RangePicker } = TimePicker;

const initialTimeFormat = "hh:mm:ss a";
const initialRangeValues = [moment.utc(moment()), moment.utc(moment().utc().add(12, 'hours'))];

const TimeFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors }) => {
    let currentTimeFormat = formRef.current.getFieldValue(["fields", selectedFieldRow.name, "timeFormat"]);
    const initialTimeExampleValue = moment().format(currentTimeFormat || initialTimeFormat);

    const [timeExampleValue, setTimeExampleValue] = useState(initialTimeExampleValue);
    const [rangeFormat, setRangeFormat] = useState(currentTimeFormat || initialTimeFormat);

    const onTimeFormatChange = (timeFormat, selectedFieldRow) => {
        let timeExampleValue = moment().format(`${timeFormat}`);
        setTimeExampleValue(timeExampleValue);
        setRangeFormat(timeFormat);
    }

    return (
        <>
            <Segment.Group>
                <Segment>
                    <Message info>
                        <Message.Header>Preview</Message.Header>
                        <p>{timeExampleValue}</p>
                    </Message>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Time Format"
                        name={["fields", selectedFieldRow.name, "timeFormat"]}
                        initialValue={initialTimeFormat}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.timeFormat`)}>
                        <Select onChange={e => onTimeFormatChange(e, selectedFieldRow)} {...(() => (readOnly ? { open: false } : {}))()}>
                            {Object.keys(timeFormats).map(key => (<Option key={key} value={key}>{timeFormats[key].name}</Option>))}
                        </Select>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Range"
                        name={["fields", selectedFieldRow.name, "range"]}
                        initialValue={initialRangeValues}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.range`)}>
                        <RangePicker format={rangeFormat} />
                    </Form.Item>
                </Segment>
                <Segment>
                    <SchemaClassifiersFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} getFormErrors={getFormErrors}></SchemaClassifiersFieldProperty>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Multi Value"
                        name={["fields", selectedFieldRow.name, "isMultivalue"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_MULTIVALUE_PROPERTY_CHECKBOX}></Checkbox>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="PII"
                        name={["fields", selectedFieldRow.name, "isPII"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_PII_PROPERTY_CHECKBOX}><Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data"><InfoCircleTwoTone /></Tooltip></Checkbox>
                    </Form.Item>
                </Segment>
            </Segment.Group>
        </>
    );
};

export default TimeFieldProperties;