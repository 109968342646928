import React, { useState } from "react";
import { Form, DatePicker, Input, Checkbox, Select, InputNumber, Tooltip, Empty } from "antd";
import { SchemaClassifiersFieldProperty } from 'components';
import { Segment, Message } from 'semantic-ui-react'
import { InfoCircleTwoTone } from '@ant-design/icons';
import _ from 'lodash';
import moment from 'moment';
import dateFormats from 'common/data/dateFormats';
import dateSeparators from 'common/data/dateSeparators';
import CypressTestIds from "cypress/CypressTestIds";

const { Option } = Select;
const { RangePicker } = DatePicker;

const initialDateFormat = "MM$DD$YYYY";
const initialDateSeparator = "/";
const initialDateFormatOptions = Object.keys(dateFormats).map(key => ({ name: _.replace(dateFormats[key].name, /\$/g, initialDateSeparator), value: key }));
const initialDateFormatValue = `${_.replace(initialDateFormat, /\$/g, initialDateSeparator)}`;
const initialRangeValues = [moment("01/01/1970 12:00:00 am", initialDateFormatValue), moment("01/01/9999 11:59:59 pm", initialDateFormatValue)];

const DateFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors }) => {
    let currentDateFormat = formRef.current.getFieldValue(["fields", selectedFieldRow.name, "dateFormat"]);
    const currentDateSeparator = formRef.current.getFieldValue(["fields", selectedFieldRow.name, "dateSeparator"]);
    let currentDateFormatValue = null;
    let currentDateFormatOptions = null;
    if (currentDateFormat && currentDateSeparator) {
        currentDateFormatValue = `${_.replace(currentDateFormat, /\$/g, currentDateSeparator)}`;
        currentDateFormatOptions = Object.keys(dateFormats).map(key => ({ name: _.replace(dateFormats[key].name, /\$/g, currentDateSeparator), value: key }));
    }
    const [dateFormatOptions, setDateFormatOptions] = useState(currentDateFormatOptions || initialDateFormatOptions);
    const [dateExampleValue, setDateExampleValue] = useState(moment().format(currentDateFormatValue || initialDateFormatValue));
    const [rangeFormat, setRangeFormat] = useState(currentDateFormatValue || initialDateFormatValue);

    const onDateFormatChange = (dateFormat, selectedFieldRow) => {
        let dateSeparator = formRef.current.getFieldValue(["fields", selectedFieldRow.name, "dateSeparator"]);
        let dateFormatValue = `${_.replace(dateFormat, /\$/g, dateSeparator)}`;
        let dateExampleValue = moment().format(dateFormatValue);
        setDateExampleValue(dateExampleValue);
        setRangeFormat(dateFormatValue);
    }

    const onDateSeparatorChange = (dateSeparator, selectedFieldRow) => {
        let dateFormatOptions = Object.keys(dateFormats).map(key => ({ name: _.replace(dateFormats[key].name, /\$/g, dateSeparator), value: key }));
        setDateFormatOptions(dateFormatOptions);

        let dateFormat = formRef.current.getFieldValue(["fields", selectedFieldRow.name, "dateFormat"]);
        let dateFormatValue = `${_.replace(dateFormat, /\$/g, dateSeparator)}`;

        let dateExampleValue = moment().format(dateFormatValue);
        setDateExampleValue(dateExampleValue);
        setRangeFormat(dateFormatValue);
    }

    return (
        <>
            <Segment.Group>
                <Segment>
                    <Message info>
                        <Message.Header>Preview</Message.Header>
                        <p>{dateExampleValue}</p>
                    </Message>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Date Format"
                        name={["fields", selectedFieldRow.name, "dateFormat"]}
                        initialValue={initialDateFormat}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.dateFormat`)}>
                        <Select onChange={e => onDateFormatChange(e, selectedFieldRow)} {...(() => (readOnly ? { open: false } : {}))()}>
                            {dateFormatOptions.map(format => (<Option key={format.value} value={format.value}>{format.name}</Option>))}
                        </Select>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Date Separator"
                        name={["fields", selectedFieldRow.name, "dateSeparator"]}
                        initialValue={initialDateSeparator}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.dateSeparator`)}>
                        <Select onChange={e => onDateSeparatorChange(e, selectedFieldRow)} {...(() => (readOnly ? { open: false } : {}))()}>
                            {Object.keys(dateSeparators).map(key => (<Option key={key} value={key}>{dateSeparators[key].name}</Option>))}
                        </Select>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Range"
                        name={["fields", selectedFieldRow.name, "range"]}
                        initialValue={initialRangeValues}
                        {...getFormErrors(`fields.${selectedFieldRow.name}.range`)}>
                        <RangePicker format={rangeFormat} />
                    </Form.Item>

                </Segment>
                <Segment>
                    <SchemaClassifiersFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} getFormErrors={getFormErrors}></SchemaClassifiersFieldProperty>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Multi Value"
                        name={["fields", selectedFieldRow.name, "isMultivalue"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_MULTIVALUE_PROPERTY_CHECKBOX}></Checkbox>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="PII"
                        name={["fields", selectedFieldRow.name, "isPII"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_PII_PROPERTY_CHECKBOX}><Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data"><InfoCircleTwoTone /></Tooltip></Checkbox>
                    </Form.Item>

                </Segment>
            </Segment.Group>
        </>
    );
};

export default DateFieldProperties;