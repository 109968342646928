import { put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';


function* getSystemKeyEvents(api,keyId) {
    let result = yield call([api, api.get], `${Endpoints.events}/api/v1/keys/${keyId}/events`);
    return result.data;
}

export function* getSystemKeyEventsRequest(api,{keyId}) {
    try {
        // console.log("getSystemKeyEventsRequest keyid",keyId);
        let result = yield call(getSystemKeyEvents, api,keyId);
        yield put(actions.getSystemKeyEventsSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "system key events");
        yield put(actions.getSystemKeyEventsFailure(errorObject));
    }
}
export function* watchGetSystemKeyEventsRequest(api, { params }) {
    yield call(getSystemKeyEventsRequest, api, params);
}
export default function* ({ api }) {
    yield takeLatest(actions.GET_SYSTEM_KEY_EVENTS_REQUEST, watchGetSystemKeyEventsRequest, api);
}
