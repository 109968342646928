import React, { useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Menu, Icon, Popup, Loader } from 'semantic-ui-react';
import { Tooltip, Typography, Form, Select } from 'antd';
import { usePrevious } from 'common/customHooks';

const { Text } = Typography;
const { Option } = Select;

function DataSetSelectFormItem({
    businessAreaId,
    schemaId,
    getDataSetBySchemaId,
    getSchemaDataSetResult,
    schemaDataSetResult,
    formItemProps,
    onChange,
    disabled,
    defaultItem
}) {
    const [fetchingDataSets, setFetchingDataSets] = useState(false);
    const previousGetSchemaDataSetResult = usePrevious(getSchemaDataSetResult);
    const [dataSetDict, setDataSetDict] = useState({});

    useEffect(() => {
        if (businessAreaId && schemaId) {
            setFetchingDataSets(true);
            getDataSetBySchemaId(businessAreaId, schemaId);
        }
    }, [businessAreaId, schemaId, getDataSetBySchemaId]);

    useEffect(() => {
        const dataSetDict = {};
        if (schemaDataSetResult && schemaDataSetResult.Items && schemaDataSetResult.Items.length > 0) {
            for (let dataSet of schemaDataSetResult.Items) {
                dataSetDict[dataSet.dataSetId] = dataSet;
            }
        }
        setDataSetDict(dataSetDict);
    }, [schemaDataSetResult]);

    useEffect(() => {
        if (getSchemaDataSetResult && getSchemaDataSetResult !== previousGetSchemaDataSetResult) {
            if (!getSchemaDataSetResult.success) {
                if (getSchemaDataSetResult.code === "PERMISSION_DENIED") {
                    showError("Permission denied.");
                }
                else {
                    showError("Could not able to get Datasets at this moment.");
                }
            }
            setFetchingDataSets(false);
        }
    }, [getSchemaDataSetResult, schemaDataSetResult, previousGetSchemaDataSetResult]);

    const onDataSetChange = useCallback((dataSetId) => {
        if (onChange && dataSetDict[dataSetId]) {
            onChange(dataSetId, dataSetDict[dataSetId]);
        }
    }, [dataSetDict, onChange]);

    return <Form.Item {...formItemProps}>
        <Select
            placeholder="Please select Dataset"
            loading={fetchingDataSets}
            onChange={onDataSetChange}
            disabled={disabled || !businessAreaId || !schemaId}>
            <>
                {
                    defaultItem && schemaDataSetResult.Items.findIndex(item => item.dataSetId === defaultItem.dataSetId) === -1 && <Option key={defaultItem.dataSetId} value={defaultItem.dataSetId}>{defaultItem.dataSetName}</Option>
                }
            </>
            {
                schemaDataSetResult.Items.map(dataSet => <Option key={dataSet.dataSetId} value={dataSet.dataSetId}>{dataSet.dataSetName}</Option>)
            }
        </Select>
    </Form.Item>
}

const mapStateToProps = (state, ownProps) => {
    return {
        getSchemaDataSetResult: state.dataSets.getSchemaDataSetResult,
        schemaDataSetResult: state.dataSets.schemaDataSetResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataSetBySchemaId: (businessAreaId, schemaId) => dispatch(actions.getDataSetBySchemaIdRequest(businessAreaId, schemaId)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSetSelectFormItem));