import React from "react";
import { Form, Input, Checkbox, Select, InputNumber, Tooltip, Empty } from "antd";
import { SchemaClassifiersFieldProperty } from 'components';
import allowedBooleanValues from 'common/data/allowedBooleanValues';
import { Segment } from 'semantic-ui-react'
import {
    InfoCircleTwoTone,
} from '@ant-design/icons';
import CypressTestIds from "cypress/CypressTestIds";

const { Option } = Select;

const BoolenFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors }) => {
    return (
        <>
            <Segment.Group>
                <Segment>
                    <Form.Item
                        label="Allowed Values"
                        name={["fields", selectedFieldRow.name, "allowedBooleanValues"]}
                        initialValue="y/n"
                        {...getFormErrors(`fields.${selectedFieldRow.name}.allowedBooleanValues`)}>
                        <Select {...(() => (readOnly ? { open: false } : {}))()}>
                            {Object.keys(allowedBooleanValues).map(key => (<Option key={key} value={key}>{allowedBooleanValues[key].name}</Option>))}
                        </Select>
                    </Form.Item>
                </Segment>
                <Segment>
                    <SchemaClassifiersFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} getFormErrors={getFormErrors}></SchemaClassifiersFieldProperty>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Multi Value"
                        name={["fields", selectedFieldRow.name, "isMultivalue"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly}data-testid={CypressTestIds.SCHEMA_MULTIVALUE_PROPERTY_CHECKBOX}></Checkbox>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="PII"
                        name={["fields", selectedFieldRow.name, "isPII"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_PII_PROPERTY_CHECKBOX}><Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data"><InfoCircleTwoTone /></Tooltip></Checkbox>
                    </Form.Item>

                </Segment>
            </Segment.Group>
        </>
    );
};

export default BoolenFieldProperties;