import React, { useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Row, Col, Result, Button } from "antd";
import {
    DataSyndicationSourceList,
    OkCancelConfirmationBox,
    FullHeightContainerLayout,
    LoadingOverlay,
    ProhibitedArea,
    ManageDataSyndicationSource,
    SuccessResult,
    ErrorResult
} from "components";

import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import _ from 'lodash';
import flatten from 'flat';

import { ActionStatus } from 'common/enums';
import { usePrevious } from 'common/customHooks';

function DataSyndicationSource({
    action,
    match,
    history,
    sourceId,
    permission,
    getDataSyndicationSourceList,
    getDataSyndicationSourceListAction,
    dataSyndicationSourceList,
    createDataSyndicationSource,
    createDataSyndicationSourceAction,
    updateDataSyndicationSource,
    updateDataSyndicationSourceAction,
    getDataSyndicationSource,
    getDataSyndicationSourceAction,
    existingSource
}) {
    const [busyMessage, setBusyMessage] = useState("Please wait...");
    const [result, setResult] = useState(null);
    const [mountingComponent, setMountingComponent] = useState(true);
    const [manageDataSyndicationSourceErrors, setManageDataSyndicationSourceErrors] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [deleteConfirmMessage, setDeleteConfirmMessage] = useState("");
    const previousCreateDataSyndicationSourceAction = usePrevious(createDataSyndicationSourceAction);
    const previousUpdateDataSyndicationSourceAction = usePrevious(updateDataSyndicationSourceAction);

    useEffect(() => {
        if (mountingComponent) {
            setMountingComponent(false);
            if (!action) {
                getDataSyndicationSourceList();
            }
            else {
                if (sourceId) {
                    getDataSyndicationSource(sourceId);
                }
            }
        }
    }, [mountingComponent, action, sourceId, getDataSyndicationSourceList, getDataSyndicationSource]);

    useEffect(() => {
        if (createDataSyndicationSourceAction
            && previousCreateDataSyndicationSourceAction
            && createDataSyndicationSourceAction !== previousCreateDataSyndicationSourceAction) {
            if (createDataSyndicationSourceAction.status === ActionStatus.FAILED) {
                if (createDataSyndicationSourceAction.fields) {
                    setManageDataSyndicationSourceErrors(createDataSyndicationSourceAction.fields);
                }
                else {
                    setResult({
                        success: false,
                        title: "Syndication Source",
                        message: createDataSyndicationSourceAction.message || "Syndication source cannot be created at this moment.",
                        onOkClicked: () => setResult(null)
                    });
                    setManageDataSyndicationSourceErrors({});
                }
            }
            else if (createDataSyndicationSourceAction.status === ActionStatus.SUCCESS) {
                setResult({
                    success: true,
                    title: "Syndication Source",
                    message: "Syndication source created successfully.",
                    onOkClicked: () => {
                        history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", match.params.tenant)
                            .replace(":configurationItem", match.params.configurationItem.toLowerCase()));
                    }
                });
            }
        }

    }, [createDataSyndicationSourceAction, previousCreateDataSyndicationSourceAction, history, match]);

    useEffect(() => {
        if (updateDataSyndicationSourceAction
            && previousUpdateDataSyndicationSourceAction
            && updateDataSyndicationSourceAction !== previousUpdateDataSyndicationSourceAction) {
            if (updateDataSyndicationSourceAction.status === ActionStatus.FAILED) {
                if (updateDataSyndicationSourceAction.fields) {
                    setManageDataSyndicationSourceErrors(updateDataSyndicationSourceAction.fields);
                }
                else {
                    setResult({
                        success: false,
                        title: "Syndication Source",
                        message: updateDataSyndicationSourceAction.message || "Syndication source cannot be updated at this moment.",
                        onOkClicked: () => setResult(null)
                    });
                    setManageDataSyndicationSourceErrors({});
                }
            }
            else if (updateDataSyndicationSourceAction.status === ActionStatus.SUCCESS) {
                setResult({
                    success: true,
                    title: "Syndication Source",
                    message: "Syndication source updated successfully.",
                    onOkClicked: () => {
                        history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", match.params.tenant)
                            .replace(":configurationItem", match.params.configurationItem.toLowerCase()));
                    }
                });
            }
        }

    }, [updateDataSyndicationSourceAction, previousUpdateDataSyndicationSourceAction, history, match]);

    const showAddSource = useCallback(() => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ACTION.replace(":tenant", match.params.tenant)
            .replace(":configurationItem", match.params.configurationItem.toLowerCase())
            .replace(":itemAction", "create"));
    }, [history, match]);

    const showViewSource = useCallback((selectedSourceId) => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", match.params.tenant)
            .replace(":configurationItem", match.params.configurationItem.toLowerCase())
            .replace(":itemId", selectedSourceId)
            .replace(":itemAction", "view"));
    }, [history, match]);

    const showEditSource = useCallback((selectedSourceId) => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM_ID_ACTION.replace(":tenant", match.params.tenant)
            .replace(":configurationItem", match.params.configurationItem.toLowerCase())
            .replace(":itemId", selectedSourceId)
            .replace(":itemAction", "edit"));
    }, [history, match]);

    const createSource = useCallback((source) => {
        setBusyMessage("Creating source...");
        createDataSyndicationSource(source);
    }, [createDataSyndicationSource]);

    const goBackToSourceList = useCallback(() => {
        history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", match.params.tenant)
            .replace(":configurationItem", match.params.configurationItem.toLowerCase()));
    }, [history, match]);

    const updateSource = useCallback((updateSourceId, updatedSource) => {
        if (_.isEmpty(updatedSource) === false) {
            setBusyMessage("Updating source...");
            updateDataSyndicationSource(updateSourceId, updatedSource);
        }
        else {
            goBackToSourceList();
        }
    }, [goBackToSourceList, updateDataSyndicationSource]);

    const onDeleteDestinationCancel = useCallback(() => {

    }, []);

    const onDeleteDestinationConfirmed = useCallback(() => {

    }, []);

    const showDeleteConfirmationDialog = useCallback(() => {

    }, []);

    const enableDisableSource = useCallback(() => {

    }, []);

    const onManageDataSyndicationSourceValuesChanged = useCallback((changedValues, allValues) => {
        if (_.isEmpty(manageDataSyndicationSourceErrors) === false) {
            let formErrors = { ...manageDataSyndicationSourceErrors };
            let flatObject = flatten(changedValues);
            for (let key in flatObject) {
                delete formErrors[key];
            }
            setManageDataSyndicationSourceErrors(formErrors)
        }
    }, [manageDataSyndicationSourceErrors]);

    const isBusy = useCallback(() => {
        if (getDataSyndicationSourceListAction.status === ActionStatus.PENDING ||
            createDataSyndicationSourceAction.status === ActionStatus.PENDING ||
            updateDataSyndicationSourceAction.status === ActionStatus.PENDING ||
            getDataSyndicationSourceAction.status === ActionStatus.PENDING) {
            return true;
        }
        return false;
    },
        [
            getDataSyndicationSourceListAction,
            createDataSyndicationSourceAction,
            updateDataSyndicationSourceAction,
            getDataSyndicationSourceAction
        ]);

    const getComponentContent = useCallback(() => {
        if (!action) {
            return (
                <>
                    <OkCancelConfirmationBox
                        show={showDeleteConfirmation}
                        message={deleteConfirmMessage}
                        onCancel={onDeleteDestinationCancel}
                        onOk={onDeleteDestinationConfirmed}>
                    </OkCancelConfirmationBox>
                    {
                        getDataSyndicationSourceListAction.status !== ActionStatus.PENDING
                            ?
                            <DataSyndicationSourceList
                                items={dataSyndicationSourceList.Items}
                                onAddSource={showAddSource}
                                onViewSource={showViewSource}
                                onEditSource={showEditSource}
                                onDeleteSource={showDeleteConfirmationDialog}
                                onEnableDisableSource={enableDisableSource}
                            />
                            :
                            <></>
                    }
                </>
            );
        }

        switch (action.toLowerCase()) {
            case "create":
                return <ManageDataSyndicationSource
                    action={action}
                    permission={permission}
                    formErrors={manageDataSyndicationSourceErrors}
                    onValuesChanged={onManageDataSyndicationSourceValuesChanged}
                    onSave={createSource}
                    onCancel={goBackToSourceList}>
                </ManageDataSyndicationSource>;
            case "view":
            case "edit":
                if (getDataSyndicationSourceAction.status === ActionStatus.SUCCESS) {
                    if (existingSource) {
                        return <ManageDataSyndicationSource
                            action={action}
                            permission={permission}
                            readOnlyView={action === "view"}
                            formErrors={manageDataSyndicationSourceErrors}
                            syndicationSourceVaue={existingSource}
                            onValuesChanged={onManageDataSyndicationSourceValuesChanged}
                            onSave={updateSource}
                            onCancel={goBackToSourceList}>
                        </ManageDataSyndicationSource>;
                    }
                    else {
                        return <Result
                            status="warning"
                            title="Syndication Source not found."
                            subTitle="The Syndication Source you are looking for does not exist."
                            extra={<Button type="primary" onClick={goBackToSourceList}>Go Back</Button>}
                        />;
                    }
                }
                return <></>;
            case "history":
                return <></>
            default:
                return <></>
        }
    },
        [
            action,
            showDeleteConfirmation,
            deleteConfirmMessage,
            onDeleteDestinationCancel,
            onDeleteDestinationConfirmed,
            getDataSyndicationSourceListAction,
            dataSyndicationSourceList,
            showAddSource,
            showViewSource,
            showEditSource,
            showDeleteConfirmationDialog,
            enableDisableSource,
            createSource,
            goBackToSourceList,
            getDataSyndicationSourceAction,
            existingSource,
            permission,
            onManageDataSyndicationSourceValuesChanged,
            manageDataSyndicationSourceErrors,
            updateSource
        ]);

    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                {
                    result && <>
                        <SuccessResult
                            show={result.success}
                            title={result.title}
                            subTitle={result.message}
                            onOkClick={result.onOkClicked}>
                        </SuccessResult>
                        <ErrorResult
                            show={!result.success}
                            title={result.title}
                            subTitle={result.message}
                            onOkClick={result.onOkClicked}>
                        </ErrorResult>
                    </>
                }
                <LoadingOverlay
                    busy={isBusy()}
                    spinner
                    message={busyMessage || "Please wait..."}>
                </LoadingOverlay>
                {getComponentContent()}
            </Col>
        </Row>);
}

const mapStateToProps = (state, ownProps) => {
    return {
        getDataSyndicationSourceListAction: state.dataSyndicationSource.getDataSyndicationSourceListAction,
        dataSyndicationSourceList: state.dataSyndicationSource.dataSyndicationSourceList,
        createDataSyndicationSourceAction: state.dataSyndicationSource.createDataSyndicationSourceAction,
        getDataSyndicationSourceAction: state.dataSyndicationSource.getDataSyndicationSourceAction,
        existingSource: state.dataSyndicationSource.source,
        updateDataSyndicationSourceAction: state.dataSyndicationSource.updateDataSyndicationSourceAction,
        permission: { canEdit: true, canView: true, canDelete: true, canAdd: true }
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDataSyndicationSourceList: () => dispatch(actions.getDataSyndicationSourceListRequest()),
        createDataSyndicationSource: (source) => dispatch(actions.createDataSyndicationSourceRequest(source)),
        getDataSyndicationSource: (sourceId) => dispatch(actions.getDataSyndicationSourceRequest(sourceId)),
        updateDataSyndicationSource: (sourceId, updatedSource) => dispatch(actions.updateDataSyndicationSourceRequest(sourceId, updatedSource)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataSyndicationSource));