import React, { Component } from "react";
import { Menu, Image, Popup } from 'semantic-ui-react';
import AppPaths from 'constants/appPaths';
import { UserProfileMenu } from 'containers';
import { BusinessAreaSelectMenu } from 'containers';
import { MonitorOutlined, SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react'
import { Tooltip } from 'antd';
import CypressTestIds from "cypress/CypressTestIds";

function getActiveBusinessAreaName(userBusinessAreas, activeBusinessAreaId) {
    let activeBusinessArea = userBusinessAreas.find(businessArea => businessArea.businessAreaId === activeBusinessAreaId);
    if (activeBusinessArea) {
        return activeBusinessArea.name.toLowerCase();
    }
    return "default";
}

function HeaderMenu({ user, ...props }) {
    const [userProfileMenuOpen, setUserProfileMenuOpen] = React.useState(false)
    return <>
        <Menu inverted className="header-menu">
            <Menu inverted className="header-menu">
                <Menu.Item>
                    <Image src='/icons/header-menu/pretectum-logo.png' />
                    <div className="header-icon-label">Pretectum</div>
                </Menu.Item>
            </Menu>
            {
                user && user.isGlobalAdmin
                    ?
                    <Menu.Item name='adminConsole' active={props.page === 'adminConsole'}
                        onClick={() => props.history.push(AppPaths.ADMIN_CONSOLE)}>
                        <Icon name='code' />
                        <div className="item-label">Admin Console</div>
                    </Menu.Item>
                    :
                    <></>
            }
            <Menu.Item name='home' active={props.page === 'home'}
                onClick={() => props.history.push(AppPaths.TENANT_HOME.replace(":tenant", props.tenant.name))}
                data-testid={CypressTestIds.HEADER_MENU_HOME}>
                <Image src='/icons/header-menu/home.png' />
                <div className="item-label">Home</div>
            </Menu.Item>
            <Menu.Item name='schema' active={props.page === 'schema'}
                onClick={() => props.history.push(AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", props.tenant.name).replace(":businessarea", getActiveBusinessAreaName(props.userBusinessAreas, props.activeBusinessAreaId)))}
                data-testid={CypressTestIds.HEADER_MENU_SCHEMA}>
                <Image src='/icons/header-menu/schema.png' />
                <div className="item-label">Schema</div>
            </Menu.Item>
            <Menu.Item name='relationships' active={props.page === 'lineagePage'}
                onClick={() => props.history.push(AppPaths.TENANT_LINEAGE.replace(":tenant", props.tenant.name))}
                data-testid={CypressTestIds.HEADER_MENU_RELATIONSHIPS}>
                <Icon name='boxes' />
                <div className="item-label ">Relationships</div>
            </Menu.Item>
            <Menu.Item name='search' active={props.page === 'search'}
                onClick={() => props.history.push(AppPaths.TENANT_DATA_OBJECT_SEARCH.replace(":tenant", props.tenant.name))}
                data-testid={CypressTestIds.HEADER_MENU_SEARCH}>
                <SearchOutlined />
                <div className="item-label">Search</div>
            </Menu.Item>
            <Menu.Item name='dataobjectduplicatesearchpage' active={props.page === 'dataobjectduplicatesearchpage'}
                onClick={() => props.history.push(AppPaths.TENANT_DATA_OBJECT_DUPLICATE_SEARCH.replace(":tenant", props.tenant.name))}
                data-testid={CypressTestIds.HEADER_MENU_SEARCH_DUPLICATE}>
                <FileSearchOutlined />
                <div className="item-label">Search Duplicates</div>
            </Menu.Item>
            <Menu.Menu position='right'>
                <BusinessAreaSelectMenu />
                <Menu.Item name='domainserviceconsole' active={props.page === 'domainserviceconsole'}
                    onClick={() => props.history.push(AppPaths.TENANT_DOMAIN_SERVICE_CONSOLE.replace(":tenant", props.tenant.name))}>
                    <Tooltip title="Domain Console Dashboard"><Image src='/icons/header-menu/domain-service-console.png' /></Tooltip>
                </Menu.Item>
                <Menu.Item name='configuration' data-testid={CypressTestIds.HEADER_MENU_CONFIGURATION} active={props.page === 'configuration'}
                    onClick={() => props.history.push(AppPaths.TENANT_CONFIGURATION.replace(":tenant", props.tenant.name))}>
                    <Tooltip title="Configurations"><Image src='/icons/header-menu/configuration.png' /></Tooltip>
                </Menu.Item>
                <Menu.Item name='monitor' active={props.page === 'monitor'}
                    onClick={() => props.history.push(AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", props.tenant.name).replace(":monitorItem", "jobs"))}>
                    <Tooltip title="Jobs and Events"><MonitorOutlined /></Tooltip>
                </Menu.Item>
                <Menu.Item name='licenseinfo' active={props.page === 'licenseinfo'}
                    onClick={() => props.history.push(AppPaths.TENANT_LICENSE_INFO.replace(":tenant", props.tenant.name))}>
                    <Tooltip title="License Info"><Image src='/icons/header-menu/license-info.png' /></Tooltip>
                </Menu.Item>
                <Menu.Item name='logout' onClick={props.userLogout}>
                    <Tooltip title="Logout"><Image src='/icons/header-menu/logout.png' /></Tooltip>
                </Menu.Item>
                <Popup
                    basic
                    position='bottom left'
                    hideOnScroll
                    offset={[0, -10]}
                    eventsEnabled={true}
                    on='hover'
                    hoverable={true}
                    onClose={() => setUserProfileMenuOpen(false)}
                    onOpen={() => setUserProfileMenuOpen(true)}
                    open={userProfileMenuOpen}
                    content={<UserProfileMenu closeMenu={() => setUserProfileMenuOpen(false)}></UserProfileMenu>}
                    trigger={<Menu.Item name='userprofile' active={props.page === 'userprofile'}>
                        <Tooltip title="User Profile"><Image src='/icons/header-menu/user.png' /></Tooltip>
                    </Menu.Item>}
                />
            </Menu.Menu>
        </Menu>
    </>
}

export default HeaderMenu;