'use strict';

const tabs = Object.freeze({
    "BUSINESS_AREAS": {
        name: "businessareas",
        description: "Business Areas"
    },
    "BUSINESS_AREA_DATA": {
        name: "businessareadata",
        description: "Business Area Data"
    },
    "DATA_CLASSIFIERS": {
        name: "dataclassifiers",
        description: "Data Classifier Tags"
    },
    "ROLES": {
        name: "roles",
        description: "Roles"
    },
    /*  {
         name: "rolegroups",
         description: "Role Groups"
     }, */
    "USERS": {
        name: "users",
        description: "Users"
    },
    "CREDENTIALS": {
        name: "credentials",
        description: "Credentials"
    },
    /* {
        name: "usergroups",
        description: "User Groups"
    }, */
    "SYSTEM_CONNECTIONS": {
        name: "systemconnections",
        description: "System Connections"
    },
    "SYSTEM_KEYS": {
        name: "systemkeys",
        description: "System Keys"
    },
    "CHANGE_DATA_CAPTURE": {
        name: "changedatacapture",
        description: "CDC Destinations"
    },
    "DATA_SYNDICATION": {
        name: "syndication",
        description: "Syndication"
    }
});

module.exports = tabs;