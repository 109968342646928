
import { put, call, takeEvery, takeLatest, select, delay } from 'redux-saga/effects'
import * as actions from './actions'
import { Endpoints } from 'services/api';
import errorHandler from 'common/errorHandler';

function* getDataSyndicationSourceList(api) {
    let result = yield call([api, api.get], `${Endpoints.dataSyndication}/api/v1/sources`);
    return result.data;
}

function* getDataSyndicationSource(api, sourceId) {
    try {
        let result = yield call([api, api.get], `${Endpoints.dataSyndication}/api/v1/sources/item/${sourceId}`);
        return result.data;
    }
    catch (error) {
        if (error.isAxiosError) {
            if (error.response && error.response.status === 404) {
                return null;
            }
        }
        throw error;
    }
}

function* createDataSyndicationSource(api, source) {
    let result = yield call([api, api.post], `${Endpoints.dataSyndication}/api/v1/sources`, source);
    return result.data;
}

function* updateDataSyndicationSource(api, sourceId, udpatedSource) {
    let response = yield call([api, api.put], `${Endpoints.dataSyndication}/api/v1/sources/item/${sourceId}`, udpatedSource);
    return response.data;
}

function* deleteDataSyndicationSource(api, sourceId) {
    yield call([api, api.delete], `${Endpoints.dataSyndication}/api/v1/sources/item/${sourceId}`);
}

export function* getDataSyndicationSourceListRequest(api) {
    try {
        const result = yield call(getDataSyndicationSourceList, api);
        yield put(actions.getDataSyndicationSourceListSuccess(result));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data syndication source list");
        yield put(actions.getDataSyndicationSourceListFailure(errorObject));
    }
}

export function* getDataSyndicationSourceRequest(api, { sourceId }) {
    try {
        let source = yield call(getDataSyndicationSource, api, sourceId);
        yield put(actions.getDataSyndicationSourceSuccess(sourceId, source));
    } catch (error) {
        let errorObject = errorHandler(error, "Get", "data syndication source");
        yield put(actions.getDataSyndicationSourceFailure(errorObject));
    }
}

export function* createDataSyndicationSourceRequest(api, { source }) {
    try {
        const newSource = yield call(createDataSyndicationSource, api, source);
        yield put(actions.createDataSyndicationSourceSuccess(newSource));
    } catch (error) {
        let errorObject = errorHandler(error, "Create", "data syndication source");
        yield put(actions.createDataSyndicationSourceFailure(errorObject));
    }
}

export function* updateDataSyndicationSourceRequest(api, { sourceId, updatedSource }) {
    try {
        const source = yield call(updateDataSyndicationSource, api, sourceId, updatedSource);
        yield put(actions.updateDataSyndicationSourceSuccess(sourceId, source));
    } catch (error) {
        let errorObject = errorHandler(error, "Update", "data syndication source");
        yield put(actions.updateDataSyndicationSourceFailure(errorObject));
    }
}

export function* deleteDataSyndicationSourceRequest(api, { sourceId }) {
    try {
        yield call(deleteDataSyndicationSource, api, sourceId);
        yield put(actions.deleteDataSyndicationSourceSuccess(sourceId));
    } catch (error) {
        let errorObject = errorHandler(error, "Delete", "data syndication source");
        yield put(actions.deleteDataSyndicationSourceFailure(errorObject));
    }
}

export function* watchGetDataSyndicationSourceListRequest(api, params) {
    yield call(getDataSyndicationSourceListRequest, api);
}

export function* watchGetDataSyndicationSourceRequest(api, { params }) {
    yield call(getDataSyndicationSourceRequest, api, params);
}

export function* watchCreateDataSyndicationSourceRequest(api, { params }) {
    yield call(createDataSyndicationSourceRequest, api, params);
}

export function* watchUpdateDataSyndicationSourceRequest(api, { params }) {
    yield call(updateDataSyndicationSourceRequest, api, params);
}

export function* watchDeleteDataSyndicationSourceRequest(api, { params }) {
    yield call(deleteDataSyndicationSourceRequest, api, params);
}

export default function* ({ api }) {
    yield takeLatest(actions.GET_DATA_SYNDICATION_SOURCE_LIST_REQUEST, watchGetDataSyndicationSourceListRequest, api);
    yield takeEvery(actions.GET_DATA_SYNDICATION_SOURCE_REQUEST, watchGetDataSyndicationSourceRequest, api);
    yield takeLatest(actions.CREATE_DATA_SYNDICATION_SOURCE_REQUEST, watchCreateDataSyndicationSourceRequest, api);
    yield takeLatest(actions.UPDATE_DATA_SYNDICATION_SOURCE_REQUEST, watchUpdateDataSyndicationSourceRequest, api);
    yield takeLatest(actions.DELETE_DATA_SYNDICATION_SOURCE_REQUEST, watchDeleteDataSyndicationSourceRequest, api);
}
