
import axios from "axios";
import { Auth } from "aws-amplify";

class Endpoint {
    constructor() {
        this.BackendEndBase = process.env.REACT_APP_BACKEND_API;
        this.tenants = this.BackendEndBase + 'tenants';
        this.users = this.BackendEndBase + 'users';
        this.permissions = this.BackendEndBase + 'permissions';
        this.schemas = this.BackendEndBase + 'schemas';
        this.datasets = this.BackendEndBase + 'datasets';
        this.jobs = this.BackendEndBase + 'jobs';
        this.events = this.BackendEndBase + 'events';
        this.search = this.BackendEndBase + 'search';
        this.dataParser = this.BackendEndBase + 'dataparser';
        this.duplicates = this.BackendEndBase + 'duplicates';
        this.aggregations = this.BackendEndBase + 'aggregations';
        this.systemConnections = this.BackendEndBase + 'systemconnections';
        this.changeDataCapture = this.BackendEndBase + 'cdc';
        this.integration = this.BackendEndBase + 'integrations';
        this.dataSyndication = this.BackendEndBase + 'syndication';
    }
}

export const Endpoints = (new Endpoint());

class API {
    constructor() {
        this.head = async (endpoint, header = {}, config = {}) => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            return axios.head(
                endpoint,
                {
                    headers: {
                        Authorization: token,
                        "Content-Type": "application/json",
                        ...header,
                    },
                    ...config,
                },
            );
        };

        this.get = async (endpoint, header = {}, config = {}) => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            try {
                // console.log("token", token);
                return axios.get(
                    endpoint,
                    {
                        headers: {
                            Authorization: token,
                            "Content-Type": "application/json",
                            ...header,
                        },
                        ...config,
                    },
                );
            }
            catch (error) {
                if (error.isAxiosError) {
                    if (error.response && error.response.status === 404) {
                        return {
                            data: null
                        };
                    }
                }
                throw error;
            }
        };

        this.getAnonymous = async (endpoint, header = {}, config = {}) => {
            try {
                return axios.get(
                    endpoint,
                    {
                        headers: {
                            "Content-Type": "application/json",
                            ...header,
                        },
                        ...config,
                    },
                );
            }
            catch (error) {
                if (error.isAxiosError) {
                    if (error.response && error.response.status === 404) {
                        return {
                            data: null
                        };
                    }
                }
                throw error;
            }
        };

        this.post = async (endpoint, data, header = {}, config = {}) => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            // console.log("token", token);
            return axios.post(endpoint, data, {
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                    ...header,
                },
                ...config,
            });
        };

        this.postAnonymous = async (endpoint, data, header = {}, config = {}) => {
            return axios.post(endpoint, data, {
                headers: {
                    "Content-Type": "application/json",
                    ...header,
                },
                ...config,
            });
        };

        this.put = async (endpoint, data, header = {}, config = {}) => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            return axios.put(endpoint, data, {
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                    ...header,
                },
                ...config,
            });
        };

        this.putAnonymous = async (endpoint, data, header = {}, config = {}) => {
            return axios.put(endpoint, data, {
                headers: {
                    "Content-Type": "application/json",
                    ...header,
                },
                ...config,
            });
        };

        this.delete = async (endpoint, header = {}, config = {}) => {
            const session = await Auth.currentSession();
            const token = session.getIdToken().getJwtToken();
            return axios.delete(endpoint, {
                headers: {
                    Authorization: token,
                    "Content-Type": "application/json",
                    ...header,
                },
                ...config,
            });
        };
    }
}

export default API;
