import react, { useEffect } from 'react';
import { Form, Input, Row, Col, Button, Space, Modal } from 'antd';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import { FullHeightContainerLayout } from 'components';
import CypressTestIds from 'cypress/CypressTestIds';

const emptyObject = {};
const SchemaCloneForm = ({ schema, allowClone, onCloneSchemaModel, onValuesChanged, formErrors }) => {
    const [form] = Form.useForm();
    useEffect(() => {
        form.setFieldsValue(schema);
    }, [schema]);

    const onFormSubmit = (values) => {
        onCloneSchemaModel({ ...schema, ...values });
    }

    const getFormErrors = fieldName => {
        if (formErrors && formErrors[fieldName]) {
            return {
                help: formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return emptyObject;
    }

    return <Form
        initialValues={schema}
        form={form}
        name="basic"
        style={{ height: "100%" }}
        onFinish={onFormSubmit}
        onValuesChange={onValuesChanged}>
        <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                <FullHeightContainerLayout
                    showHeader={false}
                    showFooter={false}
                    contentStyle={{ paddingBottom: "5px" }}
                    content={<Row>
                        <Col span={8} style={{ textAlign: 'left' }}>
                            <Form.Item
                                {...getFormErrors("name")}
                                label="Schema Name"
                                name={["name"]}
                                validateFirst={true}
                                rules={nameRules}
                                style={{ "marginBottom": "unset" }}
                                data-testid={CypressTestIds.SCHEMA_CREATE_NEW_SCHEMA_CLONE_SCHEMA_NAME_INPUT}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={16} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                            <Space className='space-stretched-first-item' style={{ width: "100%" }}>
                                <Form.Item
                                    {...getFormErrors("description")}
                                    label="Schema Description"
                                    name={["description"]}
                                    rules={descriptionRules}
                                    style={{ "marginBottom": "unset" }}
                                    data-testid={CypressTestIds.SCHEMA_CREATE_NEW_SCHEMA_CLONE_SCHEMA_DESC_INPUT}>
                                    <Input />
                                </Form.Item>
                                <Button type="primary" htmlType="submit" disabled={!allowClone}
                                data-testid={CypressTestIds.SCHEMA_CREATE_NEW_SCHEMA_CLONE_SCHEMA_CREATE_BUTTON}>Clone</Button>
                            </Space>
                        </Col>
                    </Row>}>
                </FullHeightContainerLayout>
            </Col>
        </Row>
    </Form>
}

export default SchemaCloneForm