'use strict';

const ActionStatus = Object.freeze({
    NONE: "NONE",
    PENDING: 'PENDING',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED'
});

const ScheduleRate = Object.freeze({
    MINUTES: 'minutes',
    HOURS: 'hours',
    DAYS: 'days'
});

module.exports = {
    ActionStatus,
    ScheduleRate
};