import React, { Component } from "react";
import { VerticleMenuContent } from 'components';
import { withRouter } from "react-router";
import { ProhibitedArea, ComingSoon, SystemConnections } from 'components';
import { Users, Roles, BusinessAreas, BusinessAreaData, DataTags, AppClients, ChangeDataCaptures, SystemKeys, DataSyndicationSource } from 'containers';
import { Empty } from 'antd';
import AppPaths from "constants/appPaths";
import Tabs from 'constants/configurationTabs';

class Configuration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: Object.values(Tabs)
        }
    }

    componentWillMount() {
        this.showBreadCrumbNavigationItems();
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.setBreadcrumbNavigationItems) {
            let breadcrumbNavigationItems = [
                {
                    route: AppPaths.TENANT_HOME.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Home"
                },
                {
                    route: AppPaths.TENANT_CONFIGURATION.replace(":tenant", this.props.match.params.tenant.toLowerCase()),
                    text: "Configurations"
                }
            ];
            this.setState({
                breadcrumbNavigationItems
            });
            this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
        }
    }

    showSubBreadCrumbNavigationItems = (subBreadCrumbNavigationItems) => {
        let breadcrumbNavigationItems = [...this.state.breadcrumbNavigationItems, ...subBreadCrumbNavigationItems];
        this.props.setBreadcrumbNavigationItems(breadcrumbNavigationItems);
    }


    getConfigurationItem = (activeItem) => {
        if (activeItem) {
            switch (activeItem.toLowerCase()) {
                case Tabs.USERS.name:
                    return <Users action={this.props.itemAction} userId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></Users>;
                case Tabs.ROLES.name:
                    return <Roles action={this.props.itemAction} roleId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></Roles>;
                case Tabs.BUSINESS_AREAS.name:
                    return <BusinessAreas action={this.props.itemAction} businessAreaId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></BusinessAreas>;
                case Tabs.BUSINESS_AREA_DATA.name:
                    return <BusinessAreaData action={this.props.itemAction} dataDomainId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></BusinessAreaData>;
                case Tabs.DATA_CLASSIFIERS.name:
                    return <DataTags action={this.props.itemAction} tagId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></DataTags>;
                case Tabs.CREDENTIALS.name:
                    return <AppClients action={this.props.itemAction} clientId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></AppClients>;
                case Tabs.SYSTEM_CONNECTIONS.name:
                    return <SystemConnections itemAction={this.props.itemAction} itemId={this.props.itemId} subItem={this.props.subItem} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></SystemConnections>
                case Tabs.SYSTEM_KEYS.name:
                    return <SystemKeys action={this.props.itemAction} keyId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></SystemKeys>;
                case Tabs.CHANGE_DATA_CAPTURE.name:
                    return <ChangeDataCaptures action={this.props.itemAction} destinationId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></ChangeDataCaptures>;
                case Tabs.DATA_SYNDICATION.name:
                    return <DataSyndicationSource action={this.props.itemAction} sourceId={this.props.itemId} showSubBreadCrumbNavigationItems={this.showSubBreadCrumbNavigationItems}></DataSyndicationSource>;
                default:
                    return <div>
                        <ComingSoon></ComingSoon>
                    </div>;
            }
        }

        return <Empty description="No configuration option selected"></Empty>;
    }

    onMenuItemClick = (itemName) => {
        this.props.history.push(AppPaths.TENANT_CONFIGURATION_ITEM.replace(":tenant", this.props.match.params.tenant).replace(":configurationItem", itemName.toLowerCase()));
    }

    render() {
        return <>
            {!this.props.activeItem || this.state.items.some(item => item.name === this.props.activeItem.toLowerCase()) ?
                <VerticleMenuContent
                    items={this.state.items}
                    activeItem={this.props.activeItem}
                    itemContainer={this.getConfigurationItem(this.props.activeItem)}
                    onItemClick={this.onMenuItemClick}></VerticleMenuContent>
                : <ProhibitedArea />}
        </>
    }
}

export default withRouter(Configuration);