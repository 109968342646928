import React from "react";
import { Form, Input, Checkbox, Select, InputNumber, Tooltip, Empty } from "antd";
import { Segment } from 'semantic-ui-react'
import { InfoCircleTwoTone } from '@ant-design/icons';
import { SchemaMaxFieldProperty, SchemaMinFieldProperty, SchemaClassifiersFieldProperty } from 'components';
import CypressTestIds from "cypress/CypressTestIds";

const DateDayFieldProperties = ({ formRef, selectedFieldRow, readOnly, dataType, getFormErrors }) => {
    return (
        <>
            <Segment.Group>
                <Segment>
                    <SchemaMinFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} dataType={dataType} getFormErrors={getFormErrors}></SchemaMinFieldProperty>
                </Segment>
                <Segment>
                    <SchemaMaxFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} dataType={dataType} getFormErrors={getFormErrors}></SchemaMaxFieldProperty>
                </Segment>
                <Segment>
                    <SchemaClassifiersFieldProperty selectedFieldRow={selectedFieldRow} readOnly={readOnly} getFormErrors={getFormErrors}></SchemaClassifiersFieldProperty>
                </Segment>
                <Segment>
                    <Form.Item
                        label="Multi Value"
                        name={["fields", selectedFieldRow.name, "isMultivalue"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly}  data-testid={CypressTestIds.SCHEMA_MULTIVALUE_PROPERTY_CHECKBOX}></Checkbox>
                    </Form.Item>
                </Segment>
                <Segment>
                    <Form.Item
                        label="PII"
                        name={["fields", selectedFieldRow.name, "isPII"]}
                        valuePropName="checked"
                        initialValue={false}>
                        <Checkbox disabled={readOnly} data-testid={CypressTestIds.SCHEMA_PII_PROPERTY_CHECKBOX}><Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data"><InfoCircleTwoTone /></Tooltip></Checkbox>
                    </Form.Item>

                </Segment>
            </Segment.Group>
        </>
    );
};

export default DateDayFieldProperties;