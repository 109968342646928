import React, { useState, useEffect } from "react";
import { Table, Tooltip, Pagination, Row, Col, Typography } from "antd";
import moment from 'moment';
import { BatchProcessResult, FullHeightContainerLayout, DataObjectLink } from 'components';
import {
    EventDataDomain,
    EventDomainData,
    EventSchemaModel,
    EventUser,
    EventBusinessAreaUsers,
    EventBusinessAreaAppClients,
    EventBusinessAreaSystemConnections,
    EventBusinessArea,
    EventRole,
    EventDataSet,
    EventDataObject,
    EventDataTag,
    EventAppClient,
    EventJob,
    EventSystemConnection,
    EventDataObjectVerification
} from 'containers';
import EventSystemKeys from "containers/EventSystemKeys";
import CypressTestIds from "cypress/CypressTestIds";

const { Column } = Table;
const { Link, Text, Title } = Typography;

const EventDataComponent = ({ eventId, recordType, ...props }) => {
    let component = <></>;
    switch (recordType) {
        case "DATA_DOMAIN":
            component = <EventDataDomain key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventDataDomain>;
            break;
        case "DOMAIN_DATA":
            component = <EventDomainData key={props.itemOperation ? `${props.itemOperation}-${eventId}` : eventId} eventId={eventId} {...props}></EventDomainData>
            break;
        case "SCHEMA":
            component = <EventSchemaModel key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventSchemaModel>;
            break;
        case "USER":
            component = <EventUser key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventUser>;
            break;
        case "BUSINESS_AREA_USER":
            component = <EventBusinessAreaUsers key={props.itemOperation ? `${props.itemOperation}-${eventId}` : eventId} eventId={eventId} {...props} ></EventBusinessAreaUsers>;
            break;
        case "BUSINESS_AREA_APP_CLIENT":
            component = <EventBusinessAreaAppClients key={props.itemOperation ? `${props.itemOperation}-${eventId}` : eventId} eventId={eventId} {...props} ></EventBusinessAreaAppClients>;
            break;
        case "BUSINESS_AREA_SYSTEM_CONNECTION":
            component = <EventBusinessAreaSystemConnections key={props.itemOperation ? `${props.itemOperation}-${eventId}` : eventId} eventId={eventId} {...props} ></EventBusinessAreaSystemConnections>;
            break;
        case "BUSINESS_AREA":
            component = <EventBusinessArea key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventBusinessArea>;
            break;
        case "ROLE":
            component = <EventRole key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventRole>;
            break;
        case "DATA_SET":
            component = <EventDataSet key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventDataSet>;
            break;
        case "DATA_OBJECT":
            component = <EventDataObject key={props.itemOperation ? `${props.itemOperation}-${eventId}` : eventId} eventId={eventId} {...props}></EventDataObject>
            break;
        case "DATA_TAG":
            component = <EventDataTag key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventDataTag>;
            break;
        case "APP_CLIENT":
            component = <EventAppClient key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventAppClient>;
            break;
        case "JOB":
            component = <EventJob key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventJob>;
            break;
        case "SYSTEM_CONNECTION":
            component = <EventSystemConnection key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventSystemConnection>;
            break;
        case "DATA_OBJECT_VERIFICATION":
            component = <EventDataObjectVerification key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventDataObjectVerification>;
            break;
        case "SYSTEM_INTEGRATION_KEY":
            component=  <EventSystemKeys key={`${eventId}_${recordType}`} eventId={eventId} {...props} ></EventSystemKeys>;
            break;
    }
    return <FullHeightContainerLayout
        content={component}
        showFooter={false}>
    </FullHeightContainerLayout>;
}

const EventList = ({ eventList }) => {
    // console.log("eventlist", eventList)
    const [selectedRecordEvent, setSelectedRecordEvent] = useState(null);
    const [containerHeight, setContainerHeight] = useState("100%");
    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedEventList, setPaginatedEventList] = useState(eventList && eventList.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setPaginatedEventList(eventList && eventList.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [eventList]);

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedEventList = eventList && eventList.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedEventList(currentPaginatedEventList);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
    }

    return (
        <Row style={{ flexDirection: "column", flexGrow: 1, height: "100%" }}>
            <Col span={24} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                    <Col span={24}>
                        <FullHeightContainerLayout
                            showHeader={false}
                            content={
                                <Table size="small"
                                    rowKey={(record)=> `${record.eventId}_${record.recordType}`}
                                    dataSource={paginatedEventList}
                                    pagination={false}
                                    scroll={{ y: `100vh` }}
                                    className="container-height-100"
                                    data-testid={CypressTestIds.COMMON_OBJECTS_EVENT_LIST_TABLE}>
                                    <Column
                                        width="3rem"
                                        title="No."
                                        key="index"
                                        render={(value, item, index) => index + 1}
                                    />
                                    <Column
                                        width="8rem"
                                        dataIndex={"createdDate"}
                                        title={"Date"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, job, index) => {
                                            let displayValue = null;
                                            if (value) {
                                                displayValue = moment.utc(value).toDate().toLocaleDateString();
                                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                            }
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        width="8rem"
                                        dataIndex={"createdDate"}
                                        title={"Time"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, job, index) => {
                                            let displayValue = null;
                                            if (value) {
                                                displayValue = moment.utc(value).toDate().toLocaleTimeString();
                                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                            }
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        width="15rem"
                                        dataIndex={"createdByEmail"}
                                        title={"User"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, job, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                                    />
                                    <Column
                                        width="15rem"
                                        dataIndex={"businessAreaName"}
                                        title={"Business Area"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, job, index) => <Tooltip placement="topLeft" title={value || "<<none>>"}>{value || "<<none>>"}</Tooltip>}
                                    />
                                    <Column
                                        width="20rem"
                                        dataIndex={"recordType"}
                                        title={"Data Object"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, event, index) => {
                                            let displayValue = null;
                                            if (value) {
                                                switch (value) {
                                                    case "DATA_DOMAIN":
                                                        displayValue = "Business Area Data";
                                                        break;
                                                    case "DOMAIN_DATA":
                                                        displayValue = "Domain";
                                                        break;
                                                    case "SCHEMA":
                                                        displayValue = "Schema";
                                                        break;
                                                    case "USER":
                                                        displayValue = "User";
                                                        break;
                                                    case "BUSINESS_AREA_USER":
                                                        displayValue = "Business Area Users";
                                                        break;
                                                    case "BUSINESS_AREA_APP_CLIENT":
                                                        displayValue = "Business Area Credentials";
                                                        break;
                                                    case "BUSINESS_AREA_SYSTEM_CONNECTION":
                                                        displayValue = "Business Area System Connections";
                                                        break;
                                                    case "BUSINESS_AREA":
                                                        displayValue = "Business Area";
                                                        break;
                                                    case "ROLE":
                                                        displayValue = "Roles";
                                                        break;
                                                    case "DATA_SET":
                                                        displayValue = "Dataset";
                                                        break;
                                                    case "DATA_OBJECT":
                                                        displayValue = "Dataset Object";
                                                        break;
                                                    case "DATA_TAG":
                                                        displayValue = "Data Tag";
                                                    case "APP_CLIENT":
                                                        displayValue = "Credential";
                                                        break;
                                                    case "JOB":
                                                        displayValue = "Job";
                                                        break;
                                                    case "SYSTEM_CONNECTION":
                                                        if (event.connectionType === "aws_s3") {
                                                            displayValue = "AWS S3 Connection";
                                                        }
                                                        else {
                                                            displayValue = "System Connection";
                                                        }
                                                        break;
                                                    case "SYSTEM_INTEGRATION_KEY":
                                                        displayValue = "System Key";
                                                        break;
                                                    case "DATA_OBJECT_VERIFICATION":
                                                        displayValue = "Record verification";
                                                        break;
                                                    default:
                                                        displayValue = <></>
                                                }
                                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                            }
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        width="15rem"
                                        dataIndex={"recordType"}
                                        title={"Object ID"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, event, index) => {
                                            let displayValue = null;
                                            if (value) {
                                                switch (value) {
                                                    case "DATA_DOMAIN":
                                                        displayValue = event.domainName;
                                                        break;
                                                    case "SCHEMA":
                                                        displayValue = event.schemaName;
                                                        break;
                                                    case "DOMAIN_DATA":
                                                        displayValue = event.batchProcessing ? <Text type="secondary" italic="true" style={{ maxWidth: "12rem" }}>{`<<multiple records>>`}</Text> : <></>;
                                                        break;
                                                    case "USER":
                                                        displayValue = event.userEmail;
                                                        break;
                                                    case "BUSINESS_AREA_USER":
                                                        displayValue = event.batchProcessing ? <Text type="secondary" italic="true" style={{ maxWidth: "12rem" }}>{`<<multiple records>>`}</Text> : <>{event.businessAreaUserEmail}</>;
                                                        break;
                                                    case "BUSINESS_AREA_APP_CLIENT":
                                                        displayValue = event.batchProcessing ? <Text type="secondary" italic="true" style={{ maxWidth: "12rem" }}>{`<<multiple records>>`}</Text> : <>{event.clientName}</>;
                                                        break;
                                                    case "BUSINESS_AREA_SYSTEM_CONNECTION":
                                                        displayValue = event.batchProcessing ? <Text type="secondary" italic="true" style={{ maxWidth: "12rem" }}>{`<<multiple records>>`}</Text> : <>{event.connectionName}</>;
                                                        break;
                                                    case "BUSINESS_AREA":
                                                        displayValue = event.businessAreaName;
                                                        break;
                                                    case "ROLE":
                                                        displayValue = event.roleName;
                                                        break;
                                                    case "DATA_SET":
                                                        displayValue = event.dataSetName;
                                                        break;
                                                    case "DATA_OBJECT":
                                                        displayValue = event.batchProcessing ? <Text type="secondary" italic="true" style={{ maxWidth: "12rem" }}>{`<<multiple records>>`}</Text> : <><DataObjectLink dataObjectId={event.dataObjectId}></DataObjectLink></>;
                                                        break;
                                                    case "DATA_TAG":
                                                        displayValue = event.tagName;
                                                    case "APP_CLIENT":
                                                        displayValue = event.clientName;
                                                        break;
                                                    case "JOB":
                                                        displayValue = event.jobId;
                                                        break;
                                                    case "SYSTEM_CONNECTION":
                                                        displayValue = event.connectionName;
                                                        break;
                                                    case "DATA_OBJECT_VERIFICATION":
                                                        displayValue = event.verificationId;
                                                        break;
                                                    case "SYSTEM_INTEGRATION_KEY":
                                                        displayValue = event.keyId;
                                                        break;
                                                    default:
                                                        displayValue = <></>
                                                }
                                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                            }
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={"recordType"}
                                        title={"Object Parent"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, event, index) => {
                                            let displayValue = null;
                                            if (value) {
                                                switch (value) {
                                                    case "DOMAIN_DATA":
                                                        displayValue = event.domainName;
                                                        break;
                                                    case "BUSINESS_AREA_USER":
                                                    case "BUSINESS_AREA_APP_CLIENT":
                                                    case "BUSINESS_AREA_SYSTEM_CONNECTION":
                                                        displayValue = event.businessAreaName;
                                                        break;
                                                    case "DATA_SET":
                                                        displayValue = event.schemaName;
                                                        break;
                                                    case "DATA_OBJECT":
                                                        displayValue = event.dataSetName;
                                                        break;
                                                    case "DATA_OBJECT_VERIFICATION":
                                                        displayValue = event.dataObjectId ? <DataObjectLink dataObjectId={event.dataObjectId}></DataObjectLink> : <>{"<<none>>"}</>;
                                                        break;
                                                    default:
                                                        displayValue = <>{"<<none>>"}</>
                                                }
                                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                            }
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={"eventType"}
                                        title={"Action"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, event, index) => {
                                            let displayValue = null;
                                            if (value) {
                                                switch (value) {
                                                    case "CREATED_RECORD":
                                                        displayValue = "Created";
                                                        break;
                                                    case "UPDATED_RECORD":
                                                        displayValue = "Updated";
                                                        break;
                                                    case "DELETED_RECORD":
                                                        displayValue = "Deleted";
                                                        break;
                                                    case "BATCH_PROCESSING":
                                                        displayValue = "Bulk data processed";
                                                        break;
                                                    case "DELETED_DUPLICATE_RECORD":
                                                        displayValue = "Duplicate record deleted";
                                                        break;
                                                    default:
                                                        displayValue = <></>
                                                }
                                                return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
                                            }
                                            return <></>;
                                        }}
                                    />
                                    <Column
                                        width="12rem"
                                        dataIndex={"details"}
                                        title={"Details"}
                                        ellipsis={{
                                            showTitle: false,
                                        }}
                                        render={(value, event, index) => {
                                            let displayValue = null;
                                            switch (event.eventType) {
                                                case "CREATED_RECORD":
                                                case "UPDATED_RECORD":
                                                case "DELETED_RECORD":
                                                case "DELETED_DUPLICATE_RECORD":
                                                    displayValue = <Link
                                                        ellipsis={true}
                                                        style={{ maxWidth: "12rem" }}
                                                        onClick={() => {
                                                            setSelectedRecordEvent(event);
                                                            setContainerHeight("50%");
                                                        }}>
                                                        1 Record
                                                    </Link>
                                                    break;
                                                case "BATCH_PROCESSING":
                                                    let batchProcessResult = value;
                                                    if (value &&
                                                        (event.recordType === "BUSINESS_AREA_USER" ||
                                                            event.recordType === "BUSINESS_AREA_APP_CLIENT" ||
                                                            event.recordType === "BUSINESS_AREA_SYSTEM_CONNECTION")
                                                    ) {
                                                        batchProcessResult = {
                                                            error: null,
                                                            itemOperations: value
                                                        }
                                                    }
                                                    displayValue = batchProcessResult ?
                                                        <BatchProcessResult
                                                            batchProcessResult={batchProcessResult}
                                                            textMaxWidth="12rem"
                                                            onBatchOperationClick={(item) => {
                                                                setSelectedRecordEvent({ ...event, ...item });
                                                                setContainerHeight("50%");
                                                            }}>
                                                        </BatchProcessResult>
                                                        :
                                                        <></>;
                                                    break;
                                                default:
                                                    displayValue = <></>
                                            }
                                            return displayValue;
                                        }}
                                    />
                                </Table>
                            }
                            showFooter={true}
                            footer={
                                <Row justify="end" style={{ padding: "0.2rem 0rem" }}>
                                    <Col span={24} style={{ textAlign: "end" }}>
                                        <Pagination
                                            size="small"
                                            hideOnSinglePage={true}
                                            total={eventList.length}
                                            defaultPageSize={pagination.defaultPageSize}
                                            pageSize={pagination.pageSize}
                                            responsive={true}
                                            showSizeChanger={false}
                                            onChange={onPageClick} />
                                    </Col>
                                </Row>
                            }>
                        </FullHeightContainerLayout>
                    </Col>
                </Row>
                {
                    selectedRecordEvent
                        ?
                        <Row style={{ flexDirection: "column", flexGrow: 1, height: containerHeight }}>
                            <Col span={24}>
                                <EventDataComponent
                                    eventId={selectedRecordEvent.eventId}
                                    recordType={selectedRecordEvent.recordType}
                                    tableHeight={"100vh"}
                                    {...selectedRecordEvent}></EventDataComponent>
                            </Col>
                        </Row>
                        :
                        <></>
                }
            </Col>
        </Row>
    );
};

export default EventList;