import React from "react";
import { Form, Empty } from "antd";
import { Header, Segment } from 'semantic-ui-react'
import {
    BooleanFieldProperties,
    CurrencyFieldProperties,
    DateTimeFieldProperties,
    DateFieldProperties,
    TimeFieldProperties,
    DateDayFieldProperties,
    DateMonthFieldProperties,
    DateYearFieldProperties,
    EmailFieldProperties,
    FloatFieldProperties,
    IntegerFieldProperties,
    PhoneFieldProperties,
    StringFieldProperties,
    UrlFieldProperties,
    LatLongFieldProperties,
    FullHeightContainerLayout,
} from 'components';
import { LatitudeFieldProperties,LongitudeFieldProperties } from "components/molecules/LontiudeandLatitudeFieldproperties";

import {
    SchemaPicklistFieldProperties
} from 'containers';

const FieldPropertiesView = (props) => {
    switch (props.dataType) {
        case "integer":
            return <IntegerFieldProperties {...props} />;
        case "float":
            return <FloatFieldProperties {...props} />;
        case "string":
            return <StringFieldProperties {...props} />;
        case "boolean":
            return <BooleanFieldProperties {...props} />;
        case "datetime":
            return <DateTimeFieldProperties {...props} />;
        case "date":
            return <DateFieldProperties {...props} />;
        case "time":
            return <TimeFieldProperties {...props} />;
        case "date-year":
            return <DateYearFieldProperties {...props} />;
        case "date-month":
            return <DateMonthFieldProperties {...props} />;
        case "date-day":
            return <DateDayFieldProperties {...props} />;
        case "currency":
            return <CurrencyFieldProperties {...props} />;
        case "picklist":
            return <SchemaPicklistFieldProperties {...props} />;
        case "email":
            return <EmailFieldProperties {...props} />;
        case "url":
            return <UrlFieldProperties {...props} />;
        case "phone":
            return <PhoneFieldProperties {...props} />;
        case "latlng":
            return <LatLongFieldProperties {...props} />;
        case "latitude":
            return <LatitudeFieldProperties {...props} />;
        case "longitude":
            return <LongitudeFieldProperties {...props} />;
    }
    return <StringFieldProperties {...props} />;
}

const SchemaModelFieldProperties = ({ formRef, selectedFieldRow, readOnly, fieldDataType, getFormErrors, getBusinessAreaDataForBusinessAreaResult, businessAreaDataList }) => {
    if (!selectedFieldRow) {
        return <>
            <Header as='h5' dividing >Schema field attributes</Header>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No schema field selected" />
        </>;
    }

    return (
        <FullHeightContainerLayout
            showHeader={true}
            showFooter={false}
            header={<Form.Item shouldUpdate={true} className="segment-group-header-item">
                {() => {
                    return <Header as='h5' dividing>
                        {formRef.current.getFieldValue(["fields", selectedFieldRow.name, "name"]) || "<<Empty>>"}
                    </Header>;
                }}
            </Form.Item>}
            content={<FieldPropertiesView
                key={selectedFieldRow.name}
                formRef={formRef}
                selectedFieldRow={selectedFieldRow}
                readOnly={readOnly}
                dataType={fieldDataType}
                getFormErrors={getFormErrors}
                getBusinessAreaDataForBusinessAreaResult={getBusinessAreaDataForBusinessAreaResult}
                businessAreaDataList={businessAreaDataList} />}

        ></FullHeightContainerLayout>
    );
};

export default SchemaModelFieldProperties;