import React, { useState, useEffect } from "react";
import { Form, Input, Modal, Table, Button, Tooltip, Col, Row } from "antd";
import { Icon } from 'semantic-ui-react';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import scrollIntoView from 'scroll-into-view';
import { FullHeightContainerLayout } from 'components';
import CypressTestIds from "cypress/CypressTestIds";

const SchemaModelCreateOptions = ({ selectedOption, onOptionSelected }) => {
    const [selectedButton, setSelectedButton] = useState(selectedOption);

    useEffect(() => {
        setSelectedButton(selectedOption);
    }, [selectedOption]);

    const onCreateOptionClick = (option) => {
        setSelectedButton(option);
        onOptionSelected && onOptionSelected(option);
    }

    return (
        <Row style={{ marginLeft: "unset", marginRight: "unset" }}>
            <Col>
                {
                    selectedButton === "CREATE_FROM_FILE"
                        ?
                        <Button type="primary" onClick={() => onCreateOptionClick("CREATE_FROM_FILE")} data-testid={CypressTestIds.CREATE_NEW_SCHEMA_FROM_FILE_BUTTON}>Create from a file</Button>
                        :
                        <Button type="default" onClick={() => onCreateOptionClick("CREATE_FROM_FILE")} data-testid={CypressTestIds.CREATE_NEW_SCHEMA_FROM_FILE_BUTTON}>Create from a file</Button>
                }

            </Col>
            <Col style={{ paddingLeft: "1rem" }}>
                {
                    selectedButton === "CREATE_MANUALLY"
                        ?
                        <Button type="primary" onClick={() => onCreateOptionClick("CREATE_MANUALLY")} data-testid={CypressTestIds.CREATE_NEW_SCHEMA_MANUALLY_BUTTON}>Define fields manually</Button>
                        :
                        <Button type="default" onClick={() => onCreateOptionClick("CREATE_MANUALLY")} data-testid={CypressTestIds.CREATE_NEW_SCHEMA_MANUALLY_BUTTON}>Define fields manually</Button>
                }
            </Col>
            <Col style={{ paddingLeft: "1rem" }}>
                {
                    selectedButton === "CLONE_EXISTING_SCHEMA"
                        ?
                        <Button type="primary" onClick={() => onCreateOptionClick("CLONE_EXISTING_SCHEMA")} data-testid={CypressTestIds.CREATE_NEW_SCHEMA_CLONE_BUTTON}>Clone an existing schema</Button>
                        :
                        <Button type="default" onClick={() => onCreateOptionClick("CLONE_EXISTING_SCHEMA")} data-testid={CypressTestIds.CREATE_NEW_SCHEMA_CLONE_BUTTON}>Clone an existing schema</Button>
                }
            </Col>
        </Row>
    );
};

export default SchemaModelCreateOptions;