import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { Pie, Bar } from '@ant-design/plots';
import { LoadingOverlay, DuplicateDataObjectMergeRulesBuilder, FullHeightContainerLayout } from "components";
import { NominatedValueSelectionList } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Row, Col, Modal, Empty, Switch, Space } from 'antd';
import ReactDOM from 'react-dom';
import _ from 'lodash';

class DataObjectDuplicateMergeRules extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingSchema: true,
            fetchingRules: true,
            showMergeRules: true,
            busy: false,
            busyMessage: "Please wait..."
        }
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.setState({
            fetchingSchema: true,
            fetchingRules: true
        })
        this.props.getSchemaModel(this.props.businessAreaId, this.props.schemaId);
        this.props.getDuplicateMergeRules(this.props.duplicateSetId, this.props.duplicateSetVersion, this.props.groupId, this.props.dataObjectId)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetSchemaModelResultChanged(prevProps);
        this.onGetDuplicateMergeRulesResultChanged(prevProps);
        this.onSaveDuplicateMergeRulesResultChanged(prevProps);
    }

    onGetSchemaModelResultChanged = (prevProps) => {
        if (this.props.getSchemaModelResult && this.props.getSchemaModelResult !== prevProps.getSchemaModelResult) {
            if (!this.props.getSchemaModelResult.success) {
                showError("Could not able to get schema at this moment");
            }
            this.setState({
                fetchingSchema: false
            })
        }
    }

    onGetDuplicateMergeRulesResultChanged = (prevProps) => {
        if (this.props.getDuplicateMergeRulesResult && this.props.getDuplicateMergeRulesResult !== prevProps.getDuplicateMergeRulesResult) {
            if (!this.props.getDuplicateMergeRulesResult.success) {
                showError("Could not able to get rules at this moment");
            }
            this.setState({
                fetchingRules: false
            })
        }
    }

    onSaveDuplicateMergeRulesResultChanged = (prevProps) => {
        if (this.props.saveDuplicateMergeRulesResult && this.props.saveDuplicateMergeRulesResult !== prevProps.saveDuplicateMergeRulesResult) {
            if (!this.props.saveDuplicateMergeRulesResult.success) {
                showError("Could not able to saved rules at this moment");
            }
            else {
                this.setState({
                    showMergeRules: false
                })
                this.props.onClose();
            }
            this.setState({
                savingRules: false
            })
        }
    }

    onOkClicK = () => {
        this.setState({
            showMergeRules: false
        })
        this.props.onClose();
    };

    onCancelClick = () => {
        this.setState({
            showMergeRules: false
        })
        this.props.onClose();
    };

    onSaveRules = (rules) => {
        this.setState({
            busyMessage: "Saving rules...",
            savingRules: true
        })
        this.props.saveDuplicateMergeRules(this.props.duplicateSetId, this.props.duplicateSetVersion, this.props.groupId, this.props.dataObjectId, rules);
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.state.fetchingSchema || this.state.fetchingRules || this.state.savingRules;
        return isBusy;
    }

    onSelectNominatedValueClick = (formField, fieldName, nominatedValue, formRef) => {
        this.setState({
            nominatedValueParams: {
                formField,
                fieldName,
                formRef,
                nominatedValue
            }
        });
    }

    onSelectNominatedValueCancelClick = () => {
        this.setState({
            nominatedValueParams: null
        });
    }

    onSelectNominatedValueOkClick = (selectedValue) => {
        if (selectedValue) {
            let formData = { ...this.state.nominatedValueParams.formRef.current.getFieldsValue() };
            formData.rules[this.state.nominatedValueParams.formField.name].nominatedValue = selectedValue;
            this.state.nominatedValueParams.formRef.current.setFieldsValue(formData);
        }
        this.setState({
            nominatedValueParams: null
        });
    }

    getComponent = () => {
        return <FullHeightContainerLayout
            showHeader={false}
            showFooter={false}
            content={
                <>
                    <DuplicateDataObjectMergeRulesBuilder
                        schemaModel={this.props.schemaModel}
                        rules={this.props.duplicateMergeRulesResult.Items}
                        onClose={this.onCancelClick}
                        onSaveRules={this.onSaveRules}
                        onSelectNominatedValueClick={this.onSelectNominatedValueClick}>
                    </DuplicateDataObjectMergeRulesBuilder>
                    {
                        this.state.nominatedValueParams
                            ?
                            <NominatedValueSelectionList
                                dataObjectGroup={this.props.dataObjectGroup}
                                fieldName={this.state.nominatedValueParams.fieldName}
                                selectedValue={this.state.nominatedValueParams.nominatedValue}
                                onCancelClick={this.onSelectNominatedValueCancelClick}
                                onOkClick={this.onSelectNominatedValueOkClick}>
                            </NominatedValueSelectionList>
                            :
                            <></>
                    }
                </>
            }>
        </FullHeightContainerLayout>
    }

    render() {
        return <Modal
            destroyOnClose={true}
            closable={false}
            maskClosable={false}
            keyboard={false}
            title="Merge Rules"
            style={{ top: 20 }}
            open={this.state.showMergeRules}
            visible={this.state.showMergeRules}
            onOk={this.onOkClicK}
            onCancel={this.onCancelClick}
            bodyStyle={{ height: "80vh",minHeight:'50vh' }}
            width="90vh"
            footer={null}>
            <Row style={{ background: "#fffff", overflowY: "auto", width: "100%", height: "100%",padding:'12px',marginBottom:'8px' }} className="layout-row">
                <Col span={24} style={{ display: "flex", flexDirection: "column", flexWrap: "nowrap",marginBottom:'4px' }}>
                    <LoadingOverlay
                        busy={this.isBusy()}
                        spinner
                        message={this.state.busyMessage || "Please wait..."}>
                    </LoadingOverlay>
                    {!this.isBusy() ? this.getComponent() : <></>}
                </Col>
            </Row>
        </Modal>
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        getSchemaModelResult: state.schemaModels.getSchemaModelResult,
        schemaModel: state.schemaModels.schemaModel,
        getDuplicateMergeRulesResult: state.dataObjectDuplicateMergeRules.getDuplicateMergeRulesResult,
        duplicateMergeRulesResult: state.dataObjectDuplicateMergeRules.duplicateMergeRulesResult,
        saveDuplicateMergeRulesResult: state.dataObjectDuplicateMergeRules.saveDuplicateMergeRulesResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getSchemaModel: (businessAreaId, schemaId) => dispatch(actions.getSchemaModelRequest(businessAreaId, schemaId)),
        getDuplicateMergeRules: (duplicateSetId, duplicateSetVersion, groupId, dataObjectId) => dispatch(actions.getDuplicateMergeRulesRequest(duplicateSetId, duplicateSetVersion, groupId, dataObjectId)),
        saveDuplicateMergeRules: (duplicateSetId, duplicateSetVersion, groupId, dataObjectId, rules) => dispatch(actions.saveDuplicateMergeRulesRequest(duplicateSetId, duplicateSetVersion, groupId, dataObjectId, rules)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObjectDuplicateMergeRules));