import React, { useEffect, useState, useCallback } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";
import { Menu, Icon, Popup, Loader } from 'semantic-ui-react';
import { Tooltip, Typography, Form, Select } from 'antd';
import { usePrevious } from 'common/customHooks';

const { Text } = Typography;
const { Option } = Select;

function BusinessAreaSelectFormItem({
    getUserBusinessAreas,
    getUserBusinessAreasResult,
    businessAreas,
    formItemProps,
    disabled,
    onChange }) {
    const [fetchingBusinessAreas, setFetchingBusinessAreas] = useState(false);
    const previousGetUserBusinessAreasResult = usePrevious(getUserBusinessAreasResult);
    const [businessAreaDict, setBusinessAreaDict] = useState({});

    useEffect(() => {
        if (!businessAreas && businessAreas.length === 0) {
            setFetchingBusinessAreas(true);
            getUserBusinessAreas();
        }
        else {
            setFetchingBusinessAreas(false);
        }
    }, []);

    useEffect(() => {
        const businessAreaDict = {};
        if (businessAreas && businessAreas.length > 0) {
            for (let businessArea of businessAreas) {
                businessAreaDict[businessArea.businessAreaId] = businessArea;
            }
        }
        setBusinessAreaDict(businessAreaDict);
    }, [businessAreas]);

    useEffect(() => {
        if (getUserBusinessAreasResult && getUserBusinessAreasResult !== previousGetUserBusinessAreasResult) {
            if (!getUserBusinessAreasResult.success) {
                if (getUserBusinessAreasResult.code === "PERMISSION_DENIED") {
                    showError("Business Areas permission denied.");
                }
                else {
                    showError("Could not able to get Business Areas at this moment.");
                }
            }
            setFetchingBusinessAreas(false);
        }
    }, [getUserBusinessAreasResult, businessAreas, previousGetUserBusinessAreasResult]);

    const onBusinessAreaChange = useCallback((businessAreaId) => {
        if (onChange && businessAreaDict[businessAreaId]) {
            onChange(businessAreaId, businessAreaDict[businessAreaId]);
        }
    }, [businessAreaDict, onChange]);

    return <Form.Item {...formItemProps}>
        <Select
            placeholder="Please select business area"
            loading={fetchingBusinessAreas}
            onChange={onBusinessAreaChange}
            disabled={disabled}>
            {
                businessAreas.map(businessArea => <Option key={businessArea.businessAreaId} value={businessArea.businessAreaId}>{businessArea.name}</Option>)
            }
        </Select>
    </Form.Item>
}

const mapStateToProps = (state, ownProps) => {
    return {
        businessAreas: state.home.businessAreas,
        getUserBusinessAreasResult: state.home.getUserBusinessAreasResult,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserBusinessAreas: () => dispatch(actions.getUserBusinessAreasRequest()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BusinessAreaSelectFormItem));