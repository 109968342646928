import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, Select, Table, AutoComplete, Typography } from 'antd';
import { InboxOutlined, FileTwoTone } from '@ant-design/icons';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import _ from 'lodash';
import classifiers from 'common/data/classifiers';
import parsePath from 'parse-filepath';
import CypressTestIds from "cypress/CypressTestIds";

const { Option } = Select;
const { Column } = Table;
const { Title, Text } = Typography;

class ManageDataTag extends Component {

    constructor(props) {
        super(props);
        this.dataTagDefault = {
        }
        this.formRef = React.createRef();
        this.state = {
            readOnlyView: this.props.action === "view"
        };
        this.emptyObject = {};
    }

    componentWillMount() {

    }

    componentDidMount() {
        if (this.props.dataTag) {
            this.formRef.current.setFieldsValue({ ...this.props.dataTag });
        }
        else {
            this.selectDefaultBusinessArea();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.businessAreaList !== prevProps.businessAreaList && this.props.businessAreaList.length > 0) {
            this.selectDefaultBusinessArea();
        }
    }

    selectDefaultBusinessArea = () => {
        if (this.props.businessAreaList.length > 0) {
            if (this.formRef.current) {
                let selectedBusinessAreaId = this.formRef.current.getFieldValue(["businessAreaId"]);
                if (!selectedBusinessAreaId) {
                    this.formRef.current.setFieldsValue({ businessAreaId: this.props.businessAreaList[0].businessAreaId });
                }
            }
        }
    }

    onFormSubmit = (formData) => {
        if (!this.isSaveAllowed()) {
            return;
        }
        if (this.props.action === "edit") {
            this.props.updateDataTag(this.props.dataTag, { ...formData });
        }
        else {
            let dataTag = { ...this.dataTagDefault, ...formData };
            this.props.createDataTag(dataTag);
        }
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    onFieldsChange = (_, allFields) => {
        if (this.formRef.current) {
            let uploadFile = this.formRef.current.getFieldValue(["uploadFile"]);
            if (uploadFile) {
                this.setState({
                    uploadFileExist: true
                })
            }
        }
    }

    render() {
        let businessAreaOptions = this.props.businessAreaList.map(businessArea => ({ id: businessArea.businessAreaId, name: businessArea.name }));
        let dataTags = classifiers.map(classifier => ({ value: classifier.name }));
        return (
            <>
                <Form
                    labelCol={{ span: 3 }}
                    layout="horizontal"
                    labelAlign="left"
                    ref={this.formRef}
                    name="basic"
                    onFinish={this.onFormSubmit}
                    initialValues={this.dataTagDefault}
                    onFieldsChange={this.onFieldsChange}
                    onValuesChange={this.props.onValuesChanged}>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                className="wrap-label"
                                {...this.getFormErrors("businessAreaId")}
                                label="Business Area"
                                name={["businessAreaId"]}
                                data-testid={CypressTestIds.DATATAGS_DATATAG_BUSINESSAREA_SELECT}>
                                <Select
                                    {...(() => (this.isReadOnlyView() ? { open: false } : {}))()}>
                                    {businessAreaOptions.map(item => (<Option key={item.id || (Date.now())} value={item.id}>{item.name}</Option>))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                className="wrap-label"
                                {...this.getFormErrors("tagName")}
                                label="Tag Name"
                                name={["tagName"]}
                                validateFirst={true}
                                rules={nameRules}
                                data-testid={CypressTestIds.DATATAGS_DATATAG_NAME_INPUT}>
                                <AutoComplete
                                    disabled={this.isReadOnlyView()}
                                    options={dataTags}
                                    filterOption={(inputValue, option) =>
                                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24} style={{ textAlign: 'left' }}>
                            <Form.Item
                                {...this.getFormErrors("tagDescription")}
                                label="Tag Description"
                                name={["tagDescription"]}
                                rules={descriptionRules}
                                data-testid={CypressTestIds.DATATAGS_DATATAG_DESCRIPTION_INPUT}>
                                <Input readOnly={this.isReadOnlyView()} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col span={24} className="footer-right-column">
                            <Space>
                                <Button onClick={this.props.onCancel} data-testid={CypressTestIds.DATATAGS_DATATAG_CANCEL_BUTTON}>Cancel</Button>
                                <Button type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()} data-testid={CypressTestIds.DATATAGS_DATATAG_SAVE_BUTTON}>{this.state.uploadFileExist ? "Save and upload" : "Save"}</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

export default ManageDataTag