import React, { useEffect, useState, useRef } from "react";
import { Switch, Table, Typography, Tooltip, Pagination, Row, Col, InputNumber, Select, Descriptions, Space, Spin, Collapse, Button, Dropdown, Menu } from "antd";
import { StarFilled, StarOutlined, CloseCircleTwoTone, CheckCircleFilled, WarningFilled, CaretRightFilled, DeleteTwoTone, MergeCellsOutlined, ProfileTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import { FullHeightContainerLayout, DataObjectLink } from 'components';
import { DataObjectShareOption } from 'containers';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/fontawesome-free-solid';

const { Title, Text } = Typography;
const { Column } = Table;
const { Option } = Select;
const { Panel } = Collapse;

const getElapsedTime = (job) => {
    if (job.startedDate && job.completedDate) {
        let startDate = moment.utc(job.startedDate);
        let completedDate = moment.utc(job.completedDate);
        let duration = moment.duration(completedDate.diff(startDate));
        let displayValue = moment.utc(duration.as('milliseconds')).format('HH:mm:ss');
        return <Tooltip placement="topLeft" title={displayValue}>{displayValue}</Tooltip>
    }
    return <></>;
}

const DuplicateDataObjectList = ({
    job,
    dataTags,
    dataObjectList,
    onPageSelect,
    onDataObjectSelected,
    onShowSummaryClick,
    loadAllRecords,
    loadingMore,
    onNominateSurvivor,
    onRemoveSurvivorNomination,
    onPreviewClick,
    onMergeRulesClick,
    runDeduplicateJob }) => {

    const getFilteredDataObjectList = (dataObjectList) => {
        if (dataObjectList && dataObjectList.length > 0) {
            let filteredList = dataObjectList;
            if (selectedGroupId !== null) {
                return dataObjectList.filter(dataObject => dataObject._groupId === selectedGroupId);
            }

            if (!showExpandedGroup) {
                filteredList = dataObjectList.filter(dataObject => dataObject._groupSequence === 1);
            }
            switch (countFilter.operator) {
                case ">":
                    filteredList = filteredList.filter(dataObject => dataObject._groupSize > countFilter.value);
                    break;
                case "=":
                    filteredList = filteredList.filter(dataObject => dataObject._groupSize === countFilter.value);
                    break;
                case "<":
                    filteredList = filteredList.filter(dataObject => dataObject._groupSize < countFilter.value);
                    break;
                case ">=":
                    filteredList = filteredList.filter(dataObject => dataObject._groupSize >= countFilter.value);
                    break;
                case "<=":
                    filteredList = filteredList.filter(dataObject => dataObject._groupSize <= countFilter.value);
                    break;
                case "!=":
                    filteredList = filteredList.filter(dataObject => dataObject._groupSize != countFilter.value);
                    break;

            }
            return filteredList;
        }

        return dataObjectList;
    }

    const [showExpandedGroup, setShowExpandedGroup] = useState(true);
    const [countFilter, setCountFilter] = useState({ operator: ">=", value: 1 });
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [filteredDataObjectList, setFilteredDataObjectList] = useState(getFilteredDataObjectList(dataObjectList));
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({ defaultPageSize: 100, pageSize: 100, page: 1 })
    const [paginatedDataObjectList, setPaginatedDataObjectList] = useState(filteredDataObjectList && filteredDataObjectList.slice(pagination.page - 1, pagination.pageSize));

    useEffect(() => {
        setFilteredDataObjectList(getFilteredDataObjectList(dataObjectList));
    }, [dataObjectList, showExpandedGroup, countFilter, selectedGroupId]);

    useEffect(() => {
        setPagination({ ...pagination, page: 1 });
        if (loadAllRecords) {
            loadAllRecords(job.jobId);
        }
    }, [showExpandedGroup, countFilter, selectedGroupId]);

    useEffect(() => {
        setPaginatedDataObjectList(filteredDataObjectList && filteredDataObjectList.slice((pagination.page - 1) * pagination.pageSize, (pagination.page * pagination.pageSize)));
    }, [filteredDataObjectList]);

    const onClickRow = (record) => {
        return {
            onClick: (e) => {
                if (selectedRowKeys.length === 0 || selectedRowKeys[0] !== record._dataObjectId) {
                    setSelectedRowKeys([record._dataObjectId]);
                    onDataObjectSelected && onDataObjectSelected(filteredDataObjectList.find(dataObject => dataObject._dataObjectId === record._dataObjectId));
                }
            }
        };
    }

    const onPageClick = (page, pageSize) => {
        let currentPagination = { ...pagination };
        let currentPaginatedDataObjectList = filteredDataObjectList && filteredDataObjectList.slice((page - 1) * pageSize, (page * pageSize));
        setPaginatedDataObjectList(currentPaginatedDataObjectList);
        currentPagination.page = page;
        currentPagination.pageSize = pageSize;
        setPagination(currentPagination);
        if (currentPaginatedDataObjectList.length <= pageSize) {
            onPageSelect && onPageSelect({ current: page, pageSize }, job.jobId);
        }
    }

    let scroll = {
        x: 150 * (((dataTags && dataTags.length) || 1) + 7),
        y: `100vh`
    }

    const onDeduplicateJobClick = () => {
        runDeduplicateJob(job.jobId);
    }

    const isRemoveNomiationAllowed = (index, dataObject, paginatedDataObjectList) => {
        let allowRemoveNomiation = false;
        if (dataObject._survivor && (
            ((index - 1) >= 0 && paginatedDataObjectList[index - 1]._groupId === dataObject._groupId)
            ||
            ((index + 1) < paginatedDataObjectList.length && paginatedDataObjectList[index + 1]._groupId === dataObject._groupId)
        )
        ) {
            allowRemoveNomiation = true
        }
        return allowRemoveNomiation
    }

    const onMergeMenuClick = ({ item, key, keyPath, domEvent, dataObject }) => {
        switch (key) {
            case "PREVIEW_RECORD_MERGE":
                onPreviewClick(dataObject)
                break;
            case "MERGE_RULES":
                onMergeRulesClick(dataObject, dataObjectList.filter(item => item._groupId === dataObject._groupId))
                break;
        }
    }

    return (
        <FullHeightContainerLayout
            showHeader={true}
            headerStyle={selectedGroupId ? { height: "2rem" } : {}}
            header={
                selectedGroupId
                    ?
                    <Row style={{ height: "2rem" }}>
                        <Col span={24} style={{ height: "2rem", textAlign: "right" }}>
                            <CloseCircleTwoTone twoToneColor="red" style={{ cursor: "pointer", verticalAlign: "top" }} onClick={() => setSelectedGroupId(null)} />
                        </Col>
                    </Row>
                    :
                    <Row>
                        <Col span={24}>
                            <Row>
                                <Col span={24}>
                                    <Collapse
                                        bordered={false}>
                                        <Panel header="Job metadata" key="1">
                                            <Descriptions size="small" column={5} labelStyle={{ fontWeight: "bold" }}>
                                                <Descriptions.Item label="Config">
                                                    <Tooltip placement="topLeft" title={`${job.name} (${job.jobId})`}>{`${job.name} (${job.jobId})`}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="User">
                                                    <Tooltip placement="topLeft" title={job.createdByEmail}>{job.createdByEmail}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Started Date">
                                                    <Tooltip placement="topLeft" title={moment.utc(job.startedDate).toDate().toLocaleDateString()}>{moment.utc(job.startedDate).toDate().toLocaleDateString()}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Started Time">
                                                    <Tooltip placement="topLeft" title={moment.utc(job.startedDate).toDate().toLocaleTimeString()}>{moment.utc(job.startedDate).toDate().toLocaleTimeString()}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Completed Date">
                                                    <Tooltip placement="topLeft" title={moment.utc(job.completedDate).toDate().toLocaleDateString()}>{moment.utc(job.completedDate).toDate().toLocaleDateString()}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Completed Time">
                                                    <Tooltip placement="topLeft" title={moment.utc(job.completedDate).toDate().toLocaleTimeString()}>{moment.utc(job.completedDate).toDate().toLocaleTimeString()}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Elapsed Time">
                                                    <Tooltip placement="topLeft" title={getElapsedTime(job)}>{getElapsedTime(job)}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Tags">
                                                    <Tooltip placement="topLeft" title={job.jobInfo.dataTags.join(", ")}>{job.jobInfo.dataTags.join(", ")}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total Records">
                                                    <Tooltip placement="topLeft" title={job.runInfo.totalRecords}>{job.runInfo.totalRecords}</Tooltip>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Total Clusters">
                                                    <Tooltip placement="topLeft" title={job.runInfo.totalClusters}>{job.runInfo.totalClusters}</Tooltip>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        </Panel>
                                    </Collapse>
                                </Col>
                            </Row>
                            <Row className="deduplication-action-menu" style={{ alignContent: "center" }}>
                                <Col>
                                    <Space size="small">
                                        <Text >Grouping</Text>
                                        <Tooltip placement="topLeft" title="Toggle to expand or contract groupings.">
                                            <Switch disabled={loadingMore} checkedChildren={<Icon name='external alternate'></Icon>} unCheckedChildren={<Icon flipped="horizontally" name='external alternate'></Icon>} defaultChecked checked={showExpandedGroup} onChange={(checked, event) => setShowExpandedGroup(checked)} />
                                        </Tooltip>
                                    </Space>
                                </Col>
                                <Col>
                                    <Space size="small" style={{ paddingLeft: "1rem" }}>
                                        <Text >Count</Text>
                                        <InputNumber
                                            disabled={loadingMore}
                                            size="small"
                                            style={{ width: "8rem", verticalAlign: "middle" }}
                                            min={1}
                                            max={1000000}
                                            defaultValue={countFilter.value}
                                            value={countFilter.value}
                                            onChange={(value) => setCountFilter({ ...countFilter, value })}
                                            addonBefore={
                                                <Select
                                                    defaultValue={countFilter.operator}
                                                    value={countFilter.operator}
                                                    onChange={(value) => setCountFilter({ ...countFilter, operator: value })}>
                                                    <Option value=">">{">"}</Option>
                                                    <Option value="=">{"="}</Option>
                                                    <Option value="<">{"<"}</Option>
                                                    <Option value=">=">{">="}</Option>
                                                    <Option value="<=">{"<="}</Option>
                                                    <Option value="!=">{"!="}</Option>
                                                </Select>
                                            } />
                                    </Space>
                                </Col>
                                <Col>
                                    <Space size="small" style={{ paddingLeft: "1rem" }}>
                                        <Tooltip placement="topLeft" title="Run deduplication job">
                                            <Button size="small" icon={<CaretRightFilled />} onClick={onDeduplicateJobClick}>
                                                Run deduplication job
                                            </Button>
                                        </Tooltip>
                                    </Space>
                                </Col>
                                <Col style={{ display: "flex", flexGrow: 4, justifyContent: "end" }}>
                                    {
                                        loadingMore
                                            ?
                                            <Spin className="inline-spin-text" tip="Getting all matching records..."></Spin>
                                            :
                                            <></>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
            }
            content={
                <Table
                    dataSource={paginatedDataObjectList}
                    size="small"
                    rowKey="_dataObjectId"
                    scroll={scroll}
                    className={['container-height-100', "schema-list-table"].join(" ")}
                    pagination={false}
                    onRow={onClickRow}
                    rowSelection={{
                        selectedRowKeys,
                        type: 'radio'
                    }}
                    rowClassName={null}>
                    {
                        [
                            <Column
                                width={"8rem"}
                                title={<><>No. </></>}
                                key="index"
                                render={(value, dataObject, index) => {
                                    return <Space>
                                        <>{dataObject.sNo || index + 1} {dataObject._groupSize > 1 ? <> 
                                       <Tooltip title='Cluster'>       <Icon color="orange" name='boxes'></Icon></Tooltip>{(!showExpandedGroup && selectedGroupId === null) ? 
                                        <Tooltip title=' Singleton'>    <Icon color="blue" name='magnify' onClick={() => setSelectedGroupId(dataObject._groupId)} style={{ cursor: "pointer" }}></Icon> </Tooltip> : <></>}</> : <Icon color="blue" name='box'></Icon>}</>
                                        <>{dataObject._groupSize > 1 && dataObject._survivor && dataObject._deduplicationOption === "MERGE_DUPLICATES" && isRemoveNomiationAllowed(index, dataObject, paginatedDataObjectList)
                                            && <Dropdown
                                                overlay={<Menu onClick={(e) => onMergeMenuClick({ ...e, dataObject })}
                                                    items={[
                                                        {
                                                            label: 'Preview record merge',
                                                            key: 'PREVIEW_RECORD_MERGE',
                                                            disabled: dataObject._nominationResult === "REMOVE_IN_PROGRESS",
                                                            icon: <ProfileTwoTone disabled={dataObject._nominationResult === "REMOVE_IN_PROGRESS"} style={{ color: "yellow", cursor: "pointer" }} />
                                                        },
                                                        {
                                                            label: 'Merge rules',
                                                            key: 'MERGE_RULES',
                                                            disabled: dataObject._nominationResult === "REMOVE_IN_PROGRESS",
                                                            icon: <MergeCellsOutlined />
                                                        }
                                                    ]}
                                                />}>
                                                <FontAwesomeIcon icon={faEllipsisV}></FontAwesomeIcon>
                                            </Dropdown>}</>
                                    </Space>
                                }}
                            />,
                            <Column
                                title="Search Config ID"
                                dataIndex={"_duplicateSetId"}
                                key="_duplicateSetId"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataObject, index) => {
                                    return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                }}
                            />,
                            <Column
                                width={"8rem"}
                                title="Grouping ID"
                                dataIndex={"_groupId"}
                                key="_groupId"
                                render={(value, dataObject, index) => {
                                    let progressFeedback = null;
                                    switch (dataObject._nominationResult) {
                                        case "IN_PROGRESS":
                                            progressFeedback = <Tooltip title="Nomination save in progress"><Spin size="small"></Spin></Tooltip>
                                            break;
                                        case "SUCCESS":
                                            progressFeedback = <Tooltip title="Record nomination saved"><CheckCircleFilled style={{ color: "green" }} /></Tooltip>
                                            break;
                                        case "FAILED":
                                            progressFeedback = <Tooltip title="Record nomination failed"><WarningFilled style={{ color: "red" }} /></Tooltip>
                                            break;
                                        case "REMOVE_IN_PROGRESS":
                                            progressFeedback = <Tooltip title="Nomination removal in progress"><Spin size="small"></Spin></Tooltip>
                                            break;
                                    }
                                    let allowRemoveNomiation = isRemoveNomiationAllowed(index, dataObject, paginatedDataObjectList);
                                    return <Space>
                                        <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                        {dataObject._groupSize > 1
                                            ?
                                            (
                                                dataObject._survivor
                                                    ?
                                                    <Space>
                                                        {
                                                            allowRemoveNomiation
                                                                ?
                                                                <>
                                                                    <Tooltip title="Surviving record. Click to remove nomination">
                                                                        <StarFilled disabled={dataObject._nominationResult === "REMOVE_IN_PROGRESS"} style={{ color: "yellow", cursor: "pointer" }} onClick={() => onRemoveSurvivorNomination(dataObject)} />
                                                                    </Tooltip>
                                                                </>
                                                                :
                                                                <Tooltip title="Surviving record.">
                                                                    <StarFilled style={{ color: "yellow" }} />
                                                                </Tooltip>
                                                        }
                                                    </Space>
                                                    :
                                                    <>
                                                        <Tooltip title="Nominate surviving record">
                                                            <Dropdown overlay={<Menu onClick={(e) => {
                                                                e.stopPropagation && e.stopPropagation();
                                                                e.preventDefault && e.preventDefault();
                                                                e.stopImmediatePropagation && e.stopImmediatePropagation();
                                                            }}>
                                                                <Menu.Item onClick={(e) => {
                                                                    e.stopPropagation && e.stopPropagation();
                                                                    e.preventDefault && e.preventDefault();
                                                                    e.stopImmediatePropagation && e.stopImmediatePropagation();
                                                                    onNominateSurvivor(dataObject, "DELETE_DUPLICATES");
                                                                }}>
                                                                    <Space>
                                                                        <StarOutlined />
                                                                        <Text>Delete duplicates</Text>
                                                                    </Space>
                                                                </Menu.Item>
                                                                <Menu.Item onClick={(e) => {
                                                                    e.stopPropagation && e.stopPropagation();
                                                                    e.preventDefault && e.preventDefault();
                                                                    e.stopImmediatePropagation && e.stopImmediatePropagation();
                                                                    onNominateSurvivor(dataObject, "MERGE_DUPLICATES");
                                                                }}>
                                                                    <Space>
                                                                        <StarOutlined />
                                                                        <Text>Merge duplicates</Text>
                                                                    </Space>
                                                                </Menu.Item>
                                                            </Menu>}>
                                                                <StarOutlined onClick={(e) => {
                                                                    e.stopPropagation && e.stopPropagation();
                                                                    e.preventDefault && e.preventDefault();
                                                                    e.stopImmediatePropagation && e.stopImmediatePropagation();
                                                                }} />
                                                            </Dropdown>

                                                        </Tooltip>
                                                        {
                                                            dataObject._deleteDuplicates && <DeleteTwoTone />
                                                        }
                                                        {
                                                            dataObject._mergeDuplicates && <MergeCellsOutlined />
                                                        }
                                                    </>
                                            )
                                            :
                                            <></>
                                        }
                                        {progressFeedback}
                                    </Space>
                                }}
                            />,
                            <Column
                                width={"10rem"}
                                title="Dup Rule"
                                dataIndex={"_deduplicationOption"}
                                key="_deduplicationOption"
                                render={(value, dataObject, index) => {
                                    return value && <Text type="warning">{dataObject._deduplicationOption === "DELETE_DUPLICATES" ? "Delete duplicates" : "Merge duplicates"}</Text>
                                }}
                            />,
                            <Column
                                width={"5rem"}
                                title={"Count"}
                                dataIndex={"_groupSize"}
                                key="_groupSize"
                                render={(value, dataObject, index) => {
                                    return <Tooltip placement="topLeft" title={value}><Text strong>{showExpandedGroup || (selectedGroupId !== null) ? `${dataObject._groupSequence}/${value}` : value}</Text></Tooltip>
                                }}
                            />,
                            <Column
                                title="Business Area"
                                dataIndex={"_businessAreaName"}
                                key="_businessAreaName"
                                render={(value, dataObject, index) => {
                                    return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                }}
                            />,
                            <Column
                                title="Schema"
                                dataIndex={"_schemaName"}
                                key="_schemaName"
                                render={(value, dataObject, index) => {
                                    return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                }}
                            />,
                            <Column
                                title="Dataset"
                                dataIndex={"_dataSetName"}
                                key="_dataSetName"
                                render={(value, dataObject, index) => {
                                    return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                }}
                            />,
                            <Column
                                title="Record ID"
                                dataIndex={"_dataObjectId"}
                                key="_dataObjectId"
                                width="14rem"
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, dataObject, index) => {
                                    return (<Tooltip placement="topLeft" title={value}>
                                        <DataObjectShareOption
                                            key={value}
                                            dataObjectId={value}
                                            dataSetId={dataObject._dataSetId}
                                            schemaId={dataObject._schemaId}
                                            businessAreaId={dataObject._businessAreaId}>
                                            <DataObjectLink dataObjectId={value}></DataObjectLink>
                                        </DataObjectShareOption>
                                    </Tooltip>)
                                }}
                            />,
                            ...(dataTags && dataTags.map((tag, index) => {
                                return <Column
                                    key={index}
                                    dataIndex={tag}
                                    title={tag}
                                    render={(value, dataObject, index) => {
                                        if (dataObject._tags && dataObject._tags[tag] && dataObject._tags[tag].length > 0) {
                                            return dataObject._tags[tag].map(field => {
                                                return <Row key={field.fieldId}>
                                                    <Col><><Text strong underline>{field.name}</Text><Text strong>{":"}&nbsp;</Text></></Col>
                                                    <Col><Tooltip placement="topLeft" title={field.value}>{field.value}</Tooltip></Col>
                                                </Row>
                                            });
                                        }
                                        return <Tooltip placement="topLeft" title={value}>{value}</Tooltip>
                                    }}
                                />
                            }) || [])
                        ]
                    }
                </Table>
            }
            showFooter={true}
            footer={
                <Row justify="end" style={{ padding: "0.5rem 0rem" }}>
                    <Col span={24} style={{ textAlign: "end" }}>
                        <Pagination
                            size="small"
                            total={(filteredDataObjectList && filteredDataObjectList.length) || 0}
                            defaultPageSize={pagination.defaultPageSize}
                            pageSize={pagination.pageSize}
                            responsive={true}
                            showSizeChanger={false}
                            onChange={onPageClick}
                            current={pagination.page} />
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    );
}

export default DuplicateDataObjectList;