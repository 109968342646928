import React from 'react';
import { Form, InputNumber, Checkbox, Tooltip } from 'antd';
import { Segment } from 'semantic-ui-react';
import { InfoCircleTwoTone } from '@ant-design/icons';

const LatitudeFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors }) => {
  return (
    <Segment.Group>
      <Segment>
        <Form.Item
          label="Latitude Range"
          name={['fields', selectedFieldRow.name, 'latitudeRange']}
          initialValue={{ min: -90, max: 90 }}
          rules={[{ required: true, message: 'Latitude range is required' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.latitudeRange`)}>
          <Tooltip title="The latitude range is fixed between -90 and 90">
            <div>-90 to 90</div>
          </Tooltip>
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Latitude Precision"
          name={['fields', selectedFieldRow.name, 'latitudePrecision']}
          initialValue={4}
          rules={[{ required: true, message: 'Please enter a precision value' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.latitudePrecision`)}>
          <InputNumber min={1} max={7} disabled={readOnly} />
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Multi Value"
          name={['fields', selectedFieldRow.name, 'latitudeIsMultivalue']}
          valuePropName="checked">
          <Checkbox disabled={readOnly} />
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="PII"
          name={['fields', selectedFieldRow.name, 'latitudeIsPII']}
          valuePropName="checked">
          <Checkbox disabled={readOnly}>
            <Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data">
              <InfoCircleTwoTone />
            </Tooltip>
          </Checkbox>
        </Form.Item>
      </Segment>
    </Segment.Group>
  );
};

const LongitudeFieldProperties = ({ formRef, selectedFieldRow, readOnly, getFormErrors }) => {
  return (
    <Segment.Group>
      <Segment>
        <Form.Item
          label="Longitude Range"
          name={['fields', selectedFieldRow.name, 'longitudeRange']}
          initialValue={{ min: -180, max: 180 }}
          rules={[{ required: true, message: 'Longitude range is required' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.longitudeRange`)}>
          <Tooltip title="The longitude range is fixed between -180 and 180">
            <div>-180 to 180</div>
          </Tooltip>
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Longitude Precision"
          name={['fields', selectedFieldRow.name, 'longitudePrecision']}
          initialValue={4}
          rules={[{ required: true, message: 'Please enter a precision value' }]}
          {...getFormErrors(`fields.${selectedFieldRow.name}.longitudePrecision`)}>
          <InputNumber min={1} max={7} disabled={readOnly} />
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="Multi Value"
          name={['fields', selectedFieldRow.name, 'longitudeIsMultivalue']}
          valuePropName="checked">
          <Checkbox disabled={readOnly} />
        </Form.Item>
      </Segment>
      <Segment>
        <Form.Item
          label="PII"
          name={['fields', selectedFieldRow.name, 'longitudeIsPII']}
          valuePropName="checked">
          <Checkbox disabled={readOnly}>
            <Tooltip title="Flag this field as Personally Identifiable Information or Sensitive Data">
              <InfoCircleTwoTone />
            </Tooltip>
          </Checkbox>
        </Form.Item>
      </Segment>
    </Segment.Group>
  );
};

export { LatitudeFieldProperties, LongitudeFieldProperties };
