import React from "react";
import { Switch, Space, Button, Table, Checkbox, Row, Col, Tooltip } from "antd";
import { PlusOutlined, HistoryOutlined } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea } from "components";
import CypressTestIds from "cypress/CypressTestIds";

const { Column } = Table;

const UserList = ({ userList, onAddUser, onViewUser, onEditUser, onActivateDeactiveUser, onViewUserHistory, permission }) => {
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }

    return (
        <>
            <Table 
                data-testid={CypressTestIds.USERS_USERSLIST_TABLE}
                size="small"
                rowKey="userId"
                dataSource={userList}
                pagination={false}
                scroll={{ y: 600 }}>
                <Column
                    width="6em"
                    render={(value, user, index) => <>
                    <Tooltip title ='Edit user'>
                        <Icon
                            data-testid={CypressTestIds.USERS_USERSLIST_EDIT_BUTTON}
                            disabled={!permission.canEdit}
                            color='blue'
                            name='pencil'
                            className="action-cursor"
                            onClick={() => onEditUser(user.userId)} /> </Tooltip>
                            <Tooltip title ='View User'>
                           
                        <Icon
                            data-testid={CypressTestIds.USERS_USERSLIST_VIEW_BUTTON}
                            disabled={!permission.canView}
                            color='blue'
                            name='eye'
                            className="action-cursor"
                            onClick={() => onViewUser(user.userId)} /> </Tooltip>
                            <Tooltip title ='View User History'>
                        <Icon
                            data-testid={CypressTestIds.USERS_USERSLIST_HISTORY_BUTTON}
                            disabled={!permission.canView}
                            color='blue'
                            name='history'
                            className="action-cursor"
                            onClick={() => onViewUserHistory(user.userId)} /> </Tooltip>
                    </>}
                />
                <Column
                    dataIndex="email"
                    title="UserID"
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, user, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    dataIndex="firstName"
                    title="Name"
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, user, index) => <Tooltip placement="topLeft" title={`${user.firstName} ${user.lastName}`}>{`${user.firstName} ${user.lastName}`}</Tooltip>}
                />
                <Column
                    width="20%"
                    dataIndex="assignedBusinessAreaCount"
                    title="Assigned Business Areas"
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, user, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="15%"
                    dataIndex="assignedRoleCount"
                    title="Assigned Roles"
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, user, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                />
                <Column
                    width="10%"
                    dataIndex="active"
                    title={"Active"}
                    ellipsis={{
                        showTitle: false,
                    }}
                    render={(value, user, index) => {
                        return <Switch  data-testid={CypressTestIds.USERS_USERSLIST_ACTIVE_SWITCH} checked={value} disabled={!permission.canEdit || user.userType === "ACCOUNT_ADMIN"} onClick={(checked, event) => onActivateDeactiveUser(user, checked)}></Switch>;
                    }}
                />
            </Table>
            <Row className="table-footer-row">
                <Col span={24} className="footer-right-column">
                    <Button type="primary"
                        data-testid={CypressTestIds.USERS_ADD_USER_BUTTON}
                        disabled={!permission.canAdd}
                        onClick={onAddUser}>
                        <PlusOutlined /> Add User
                    </Button>
                </Col>
            </Row>
        </>
    );
};

export default UserList;