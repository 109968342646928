import React, { useState } from "react";
import { Tooltip, Button, Table, Checkbox, Row, Col } from "antd";
import { PlusOutlined, EditOutlined, MinusOutlined, GoldTwoTone } from "@ant-design/icons";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { ProhibitedArea, FullHeightContainerLayout } from "components";
import CypressTestIds from "cypress/CypressTestIds";

const { Column } = Table;

const SchemaModelList = ({
    permission,
    schemaModelList,
    onCreateNewSchemaModel,
    onDeleteSchemaModel,
    onViewSchemaModel,
    onEditSchemaModel,
    onViewSchemaModelHistory,
    onViewSchemaModelLineage,
    onPageSelect,
    onSchemaModelSelected,
    selectedSchemaModel
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState(selectedSchemaModel ? [selectedSchemaModel.schemaId] : []);
    const onClickRow = (record) => {
        return {
            onClick: () => {
                setSelectedRowKeys([record.schemaId]);
                onSchemaModelSelected && onSchemaModelSelected(schemaModelList.find(schema => schema.schemaId === record.schemaId));
            },
            onFocus: () => {
                setSelectedRowKeys([record.schemaId]);
                onSchemaModelSelected && onSchemaModelSelected(schemaModelList.find(schema => schema.schemaId === record.schemaId));
            },
        };
    }
    if (!permission) {
        return <ProhibitedArea></ProhibitedArea>
    }
    return (
        <FullHeightContainerLayout
            content={
                <div className="full-height-flex-container verticle-scroll">
                    <div className="full-height-flex-container">
                        <Table size="small"
                            rowKey="schemaId"
                            dataSource={schemaModelList}
                            onRow={onClickRow}
                            rowSelection={{
                                selectedRowKeys,
                                type: 'radio'
                            }}
                            scroll={{ y: "100vh" }}
                            pagination={{
                                position: ["bottomRight"],
                                defaultPageSize: 100,
                                pageSize: 100,
                                responsive: true,
                                showSizeChanger: false
                            }}
                            onChange={(pagination, filters, sorter, extra) => extra.action === "paginate" && onPageSelect && onPageSelect(pagination)}
                            className="container-height-85 schema-list-table"
                            data-testid={CypressTestIds.SCHEMA_LIST_TABLE}>
                            <Column
                                width="8rem"
                                render={(value, schema, index) => <div style={{ marginLeft: "-1rem" }}>
                                    {
                                        permission.canDelete && schema.runningJobsCount && schema.runningJobsCount > 0
                                            ?
                                            <Tooltip placement="topLeft" title="A job is running. Schema is locked for delete.">
                                                <Icon
                                                    color='blue'
                                                    name='lock' />
                                            </Tooltip>
                                            :
                                            <></>
                                    }
                                    <Tooltip title='Edit Schema'> 
                                    <Icon
                                        disabled={!permission.canEdit}
                                        color='blue'
                                        name='pencil'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onEditSchemaModel(schema.schemaId);
                                        }} 
                                        data-testid={CypressTestIds.SCHEMA_LIST_EDIT_BUTTON}/> </Tooltip>
                                        <Tooltip title='View Schema'>

                                       
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='eye'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onViewSchemaModel(schema.schemaId);
                                        }} 
                                        data-testid={CypressTestIds.SCHEMA_LIST_VIEW_BUTTON}/>  </Tooltip>
                                        <Tooltip title='Schema Change History'>
                                    <Icon
                                        disabled={!permission.canView}
                                        color='blue'
                                        name='history'
                                        className="action-cursor"
                                        onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onViewSchemaModelHistory(schema.schemaId);
                                        }} 
                                        data-testid={CypressTestIds.SCHEMA_LIST_CHANGE_HISTORY_BUTTON}/> </Tooltip>
                                    <Tooltip placement="topRight" title="Visualise relationships">
                                        <GoldTwoTone twoToneColor="#2185d0" style={{ fontSize: "22px" }} onClick={(event) => {
                                            event && event.stopPropagation && event.stopPropagation();
                                            onViewSchemaModelLineage(schema.schemaId);
                                        }} data-testid={CypressTestIds.SCHEMA_LIST_VISUALISE_RELATIONSHIPS_BUTTON}/>
                                    </Tooltip>
                                </div>}
                            />
                            <Column
                                width="17%"
                                dataIndex={"name"}
                                title={"Schema"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="10%"
                                dataIndex={"process"}
                                title={"Process"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="7%"
                                dataIndex={"fieldsCount"}
                                title={"Fields"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="7%"
                                dataIndex={"dataSetCount"}
                                title={"DataSets"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value || 0}>{value || 0}</Tooltip>}
                            />
                            <Column
                                width="8%"
                                dataIndex={"state"}
                                title={"State"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="8%"
                                dataIndex={"sharing"}
                                title={"Sharing"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => {
                                    return (
                                        <Checkbox key={schema.schemaId} checked={value}></Checkbox>
                                    );
                                }}
                            />
                            <Column
                                width="20%"
                                dataIndex={"updatedDate"}
                                title={"Last Updated"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => {
                                    let dateValue = moment.utc(value).toDate().toLocaleString()
                                    return <Tooltip placement="topLeft" title={dateValue}>{dateValue}</Tooltip>
                                }}
                            />
                            <Column
                                width="18%"
                                dataIndex={"updatedByEmail"}
                                title={"Updated By"}
                                ellipsis={{
                                    showTitle: false,
                                }}
                                render={(value, schema, index) => <Tooltip placement="topLeft" title={value}>{value}</Tooltip>}
                            />
                            <Column
                                width="3rem"
                                render={(value, schema, index) => {
                                    return (
                                        
                                            <Tooltip title='Delete Schema'>
                                        <Icon
                                            disabled={!permission.canDelete || (schema.runningJobsCount && schema.runningJobsCount > 0)}
                                            color='blue'
                                            name='trash alternate outline'
                                            className="action-cursor"
                                            onClick={(event) => {
                                                event && event.stopPropagation && event.stopPropagation();
                                                onDeleteSchemaModel(schema);
                                            }} 
                                            data-testid={CypressTestIds.SCHEMA_LIST_DELETE_BUTTON}/> </Tooltip>
                                            
                                    );
                                }}
                            />
                        </Table>
                    </div>
                </div>}
            footer={
                <Row className="table-footer-row">
                    <Col span={24} className="footer-right-column">
                        <Button
                            disabled={!permission.canAdd}
                            type="primary"
                            onClick={onCreateNewSchemaModel}
                            data-testid={CypressTestIds.CREATE_NEW_SCHEMA_BUTTON}>
                            <PlusOutlined /> Create a new schema
                        </Button>
                    </Col>
                </Row>
            }
            showFooter={true}>
        </FullHeightContainerLayout>
    );
};

export default SchemaModelList;