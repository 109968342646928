import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Button, Result, Modal } from "antd";
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;

const ErrorResult = ({ show, title, subTitle, okText, cancelText, onOkClick, onCancelClick }) => {
    return <Modal
        open={show}
        centered
        closable={false}
        maskClosable={false}
        destroyOnClose={true}
        footer={null}>
        <Result
            status="error"
            title={title}
            subTitle={subTitle}
            extra={[
                <Button type="primary" onClick={() => onOkClick && onOkClick()}>
                    {okText || "Ok"}
                </Button>,
                onCancelClick && <Button onClick={() => onCancelClick && onCancelClick()}>{cancelText || "Cancel"}</Button>,
            ]}
        />
    </Modal>
}

export default ErrorResult