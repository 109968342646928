import dayjs from 'dayjs';

import {
    GET_DATA_SYNDICATION_SOURCE_LIST_REQUEST,
    GET_DATA_SYNDICATION_SOURCE_LIST_SUCCESS,
    GET_DATA_SYNDICATION_SOURCE_LIST_FAILURE,

    GET_DATA_SYNDICATION_SOURCE_REQUEST,
    GET_DATA_SYNDICATION_SOURCE_SUCCESS,
    GET_DATA_SYNDICATION_SOURCE_FAILURE,

    CREATE_DATA_SYNDICATION_SOURCE_REQUEST,
    CREATE_DATA_SYNDICATION_SOURCE_SUCCESS,
    CREATE_DATA_SYNDICATION_SOURCE_FAILURE,

    UPDATE_DATA_SYNDICATION_SOURCE_REQUEST,
    UPDATE_DATA_SYNDICATION_SOURCE_SUCCESS,
    UPDATE_DATA_SYNDICATION_SOURCE_FAILURE,

    DELETE_DATA_SYNDICATION_SOURCE_REQUEST,
    DELETE_DATA_SYNDICATION_SOURCE_SUCCESS,
    DELETE_DATA_SYNDICATION_SOURCE_FAILURE
} from "./actions";

import { ActionStatus } from 'common/enums';

const initialState = {
    getDataSyndicationSourceListAction: {
        status: ActionStatus.NONE
    },
    dataSyndicationSourceList: {
        Items: []
    },
    getDataSyndicationSourceAction: {
        status: ActionStatus.NONE
    },
    source: null,
    createDataSyndicationSourceAction: {
        status: ActionStatus.NONE
    },
    createdSource: null,
    updateDataSyndicationSourceAction: {
        status: ActionStatus.NONE
    },
    deleteDataSyndicationSourceAction: {
        status: ActionStatus.NONE
    }
};

export default (state = initialState, action) => {
    let dataSyndicationSourceList = {
        Items: []
    };
    let sourceIndex = -1;
    switch (action.type) {
        case GET_DATA_SYNDICATION_SOURCE_LIST_REQUEST:
            return { ...state, getDataSyndicationSourceListAction: { status: ActionStatus.PENDING } };
        case GET_DATA_SYNDICATION_SOURCE_LIST_SUCCESS:
            return { ...state, getDataSyndicationSourceListAction: { status: ActionStatus.SUCCESS }, dataSyndicationSourceList: action.payload.result };
        case GET_DATA_SYNDICATION_SOURCE_LIST_FAILURE:
            return { ...state, getDataSyndicationSourceListAction: { status: ActionStatus.FAILED, ...action.payload.error }, dataSyndicationSourceList: { Items: [] } };

        case GET_DATA_SYNDICATION_SOURCE_REQUEST:
            return { ...state, getDataSyndicationSourceAction: { status: ActionStatus.PENDING }, source: null };
        case GET_DATA_SYNDICATION_SOURCE_SUCCESS:
            if (action.payload.source) {
                if (action.payload.source.validFrom) {
                    action.payload.source.validFrom = dayjs(action.payload.source.validFrom);
                }

                if (action.payload.source.validUntil) {
                    action.payload.source.validUntil = dayjs(action.payload.source.validUntil);
                }
            }
            return { ...state, getDataSyndicationSourceAction: { status: ActionStatus.SUCCESS }, source: action.payload.source };
        case GET_DATA_SYNDICATION_SOURCE_FAILURE:
            return { ...state, getDataSyndicationSourceAction: { status: ActionStatus.FAILED, ...action.payload.error }, source: null };

        case CREATE_DATA_SYNDICATION_SOURCE_REQUEST:
            return { ...state, createDataSyndicationSourceAction: { status: ActionStatus.PENDING }, createdSource: null };
        case CREATE_DATA_SYNDICATION_SOURCE_SUCCESS:
            dataSyndicationSourceList = { ...state.dataSyndicationSourceList };
            dataSyndicationSourceList.Items = [...dataSyndicationSourceList.Items];
            dataSyndicationSourceList.Items.push(action.payload.source);
            return { ...state, createDataSyndicationSourceAction: { status: ActionStatus.SUCCESS }, createdSource: action.payload.source };
        case CREATE_DATA_SYNDICATION_SOURCE_FAILURE:
            return { ...state, createDataSyndicationSourceAction: { status: ActionStatus.FAILED, ...action.payload.error }, createdSource: null };

        case UPDATE_DATA_SYNDICATION_SOURCE_REQUEST:
            return { ...state, updateDataSyndicationSourceAction: { status: ActionStatus.PENDING } };
        case UPDATE_DATA_SYNDICATION_SOURCE_SUCCESS:
            dataSyndicationSourceList = state.dataSyndicationSourceList;
            if (action.payload.source) {
                sourceIndex = dataSyndicationSourceList.Items.findIndex(item => item.sourceId === action.payload.source.sourceId);
                if (sourceIndex > -1) {
                    dataSyndicationSourceList = { ...dataSyndicationSourceList };
                    dataSyndicationSourceList.Items = [...dataSyndicationSourceList.Items];
                    dataSyndicationSourceList.Items[sourceIndex] = action.payload.source;
                }
            }
            return { ...state, updateDataSyndicationSourceAction: { status: ActionStatus.SUCCESS }, dataSyndicationSourceList };
        case UPDATE_DATA_SYNDICATION_SOURCE_FAILURE:
            return { ...state, updateDataSyndicationSourceAction: { status: ActionStatus.FAILED, ...action.payload.error } };

        case DELETE_DATA_SYNDICATION_SOURCE_REQUEST:
            return { ...state, deleteDataSyndicationSourceAction: { status: ActionStatus.PENDING } };
        case DELETE_DATA_SYNDICATION_SOURCE_SUCCESS:
            dataSyndicationSourceList = { ...state.dataSyndicationSourceList };
            dataSyndicationSourceList.Items = state.dataSyndicationSourceList.Items.filter(item => item.sourceId !== action.payload.sourceId);
            return { ...state, deleteDataSyndicationSourceAction: { status: ActionStatus.SUCCESS }, dataSyndicationSourceList };
        case DELETE_DATA_SYNDICATION_SOURCE_FAILURE:
            return { ...state, deleteDataSyndicationSourceAction: { status: ActionStatus.FAILED, ...action.payload.error } };
        default:
            return state;
    }
};
