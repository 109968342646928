import React from "react";
import { Skeleton, Empty } from "antd";
import { Card, Container } from "semantic-ui-react";

class GoogleMap extends React.Component {
  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
    this.markers = [];
    this.map = null;
    this.scriptLoaded = false;
  }

  componentDidMount() {
    this.loadGoogleMapsScript();
  }

  componentDidUpdate(prevProps) {
    if (this.props.markers !== prevProps.markers) {
      this.initMap();
    }
  }

  loadGoogleMapsScript = () => {
    const API_KEY = this.props.keyreq;

    if (!window.google && !this.scriptLoaded) {
      let script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&loading=async&callback=initGoogleMap`;
      script.async = true;
      script.onerror = (error) => {
        console.error("Error loading Google Maps script:", error);
      };
      window.initGoogleMap = () => {
        this.scriptLoaded = true;
        this.setState({ scriptLoaded: true }, this.initMap);
      };
      window.gm_authFailure = () => {
        console.error('Google Maps authentication failed. Please check your API key.');
      };
      document.head.appendChild(script);
    } else if (window.google && !this.scriptLoaded) {
      this.scriptLoaded = true;
      this.setState({ scriptLoaded: true }, this.initMap);
    }
  };

  initMap = () => {
    const mapRef = this.mapRef.current;
    console.log("idhar")
    if (!mapRef) {
      console.error("Map container element not found. Map initialization failed.");
      return;
    }

    let mapOptions;

    if (this.props.markers.length > 0) {
      const { latitude, longitude } = this.props.markers[0].position;
      mapOptions = {
        center: { lat: latitude, lng: longitude },
        zoom: 12,
      };
    } else {
      mapOptions = {
        center: { lat: 0, lng: 0 },
        zoom: 12,
      };
    }
    this.map = new window.google.maps.Map(mapRef, mapOptions);
    this.updateMarkers();
  };

  updateMarkers = () => {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
    this.props.markers.forEach((markerData) => {
      const marker = new window.google.maps.Marker({
        position: {
          lat: markerData.position.latitude,
          lng: markerData.position.longitude,
        },
        map: this.map,
      });
      this.markers.push(marker);
    });
  };

  render() {
    const { markers } = this.props;

    return (
      <Container style={{ marginTop: "10px" }}>
        <Card fluid style={{ borderRadius: "8px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)" }}>
          <Card.Content>
            <div style={{
              width: "100%",
              minHeight: "410px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#f0f2f5",
              border: "1px solid #1890ff",
              borderRadius: "8px",
              padding: "1px",
            }}>
              {!this.scriptLoaded ? (
                <Skeleton active />
              ) : markers.length === 0 ? (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No address/coordinates data available"
                />
              ) : (
                <div ref={this.mapRef} style={{ width: "100%", height: "400px" }}></div>
              )}
            </div>
          </Card.Content>
        </Card>
      </Container>
    );
  }
}

export default GoogleMap;
