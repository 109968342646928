import React, { useState } from 'react';
import { Input } from 'antd';
import isFloat from 'validator/lib/isFloat';
import { Form } from 'antd';
import { SchemaFieldTitle } from 'components';
const LongitudeInput = ({ value, onChange, precision }) => {
    const [longitude, setLongitude] = useState(value);
    const getDecimalPlaces = (num) => {
        const decimalPart = num.split('.')[1];
        return decimalPart ? decimalPart.length : 0;
    };
    const onLongitudeChange = (event) => {
        const inputValue = event.target.value.trim();
        if (inputValue === '') {
            setLongitude('');
            onChange('');
            return;
        }
        const formattedLong = parseFloat(inputValue).toFixed(precision);
        setLongitude(formattedLong);
        onChange(formattedLong);
    };
    return (
        <Input
            value={longitude}
            onChange={onLongitudeChange}
            placeholder={`Enter longitude with precision of ${precision} decimal places`}
        />
    );
}
function FormLongitudeField({
    fieldErrors,
    fieldId,
    name,
    description,
    isRequired,
    precision,
}) {
    const validateLongitude = (_, value) => {
        if (value) {
            const parsedLong = parseFloat(value);

            if (isNaN(parsedLong)) {
                return Promise.reject('Longitude must be a numeric value');
            }

            if (parsedLong < -180 || parsedLong > 180) {
                return Promise.reject('Longitude must be between -180 and 180 degrees');
            }
            const decimalPart =  value.toString().split('.')[1];
            const decimalPlaces = decimalPart ? decimalPart.length : 0;
            if (decimalPlaces > precision) {
                return Promise.reject(`Longitude cannot have more than ${precision} decimal places`);
            }

            return Promise.resolve();
        }

        return Promise.resolve();
    };

    return (
        <Form.Item
            key={fieldId}
            name={[fieldId]}
            label={
                <SchemaFieldTitle
                    name={name}
                    tags={[]}
                    orientation="horizontal"
                    tagSummary={false}
                />
            }
            tooltip={description}
            rules={[
                {
                    required: isRequired,
                    message: 'Longitude is required',
                },
                {
                    validator: validateLongitude,
                },
            ]}
            {...fieldErrors}
        >
            <LongitudeInput precision={precision} />
        </Form.Item>
    );
}

export default FormLongitudeField;

