import React, { Component } from 'react';
import { connect } from "react-redux";
import actions from 'store/actions';
import { DataObjectBulkUpload, NotFoundError, LoadingOverlay, ProhibitedArea } from "components";
import { DataObjectAudit } from 'containers';
import { showError, showSuccess } from 'common/ToastNotifications';
import AppPaths from 'constants/appPaths';
import { withRouter } from "react-router";

class DataObject extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetchingDataSetPermission: false,
            fetchingDataSet: false,
            permissionDenied: false,
            busy: false,
            busyMessage: "Please wait...",
        }
    }

    componentWillMount() {
        this.loadDataSet();
    }

    componentDidMount() {

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.onGetUserDataSetPermissionResultChanged(prevProps);
        this.onGetDataSetByNameResultChanged(prevProps);
        this.onCreateDataObjectsUploadJobResultChanged(prevProps);
    }

    loadDataSet = () => {
        if (!this.props.dataSet ||
            this.props.dataSet.dataSetName.toLowerCase() !== this.props.dataset.toLowerCase() ||
            this.props.schemaModel.name.toLowerCase() !== this.props.schema.toLowerCase() ||
            this.props.schemaModel.businessAreaId !== this.props.userBusinessAreas[this.props.businessArea].businessAreaId) {
            this.setState({
                fetchingDataSet: true
            });
            this.props.getDataSetByName(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataset);
        }
        else {
            this.showBreadCrumbNavigationItems();
            if (this.props.action === "history") {
                this.setState({
                    showHistory: true
                });
            }
        }
        this.setState({
            fetchingDataSetPermission: true
        })
        this.props.getUserDataSetPermission();
    }

    showBreadCrumbNavigationItems = () => {
        if (this.props.dataSet)
            if (this.props.setBreadcrumbNavigationItems) {
                let breadCrumbItems = [
                    {
                        route: AppPaths.TENANT_HOME.replace(":tenant", this.props.tenant.toLowerCase()),
                        text: "Home"
                    },
                    {
                        route: AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
                            .replace(":businessarea", this.props.businessArea.toLowerCase()),
                        text: "Schemas"
                    },
                    {
                        route: "",
                        text: this.props.schema
                    },
                    {
                        route: `${AppPaths.TENANT_BUSINESS_AREA_SCHEMA.replace(":tenant", this.props.tenant.toLowerCase())
                            .replace(":businessarea", this.props.businessArea.toLowerCase())}${this.props.schemaModel ? `#${this.props.schemaModel.name}` : ""}`,
                        text: "Datasets"
                    },
                    {
                        route: `${AppPaths.TENANT_SCHEMA_DATASET_ID_ACTION
                            .replace(":tenant", this.props.tenant.toLowerCase())
                            .replace(":businessarea", this.props.businessArea.toLowerCase())
                            .replace(":schema", this.props.schemaModel.name)
                            .replace(":dataSetId", this.props.dataSet.dataSetId)
                            .replace(":dataSetAction", this.props.location.state && this.props.location.state.dataSetAction ? this.props.location.state.dataSetAction : "view")}`,
                        text: this.props.dataset
                    },
                    {
                        route: "",
                        text: "Dataobjects"
                    }
                ];
                if (this.props.action) {
                    switch (this.props.action.toLowerCase()) {
                        case "history":
                            breadCrumbItems.push(...[{
                                route: "",
                                text: this.props.dataObjectId
                            },
                            {
                                route: "",
                                text: "History"
                            }]);
                            break;
                    }
                }
                this.props.setBreadcrumbNavigationItems(breadCrumbItems);
            }
    }

    onGetUserDataSetPermissionResultChanged = (prevProps) => {
        if (this.props.getUserDataSetPermissionResult && this.props.getUserDataSetPermissionResult !== prevProps.getUserDataSetPermissionResult) {
            if (!this.props.getUserDataSetPermissionResult.success || !this.props.dataSetpermission) {
                this.setState({
                    permissionDenied: true
                });
            }
            this.setState({
                fetchingDataSetPermission: false
            });
        }
    }

    onGetDataSetByNameResultChanged = (prevProps) => {
        if (this.props.getDataSetByNameResult && this.props.getDataSetByNameResult !== prevProps.getDataSetByNameResult) {
            if (!this.props.getDataSetByNameResult.success) {
                if (this.props.getDataSetByNameResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        permissionDenied: true
                    });
                }
                else {
                    showError("Could not able to get dataset.");
                }
            }
            else {
                if (this.props.action === "history") {
                    this.setState({
                        showHistory: true
                    });
                }
                this.showBreadCrumbNavigationItems();
            }
            this.setState({
                fetchingDataSet: false
            });
        }
    }

    onCreateDataObjectsUploadJobResultChanged = (prevProps) => {
        if (this.props.createDataObjectsUploadJobResult && this.props.createDataObjectsUploadJobResult !== prevProps.createDataObjectsUploadJobResult) {
            // console.log("error @@@@@", this.props.createDataObjectsUploadJobResult)
            if (!this.props.createDataObjectsUploadJobResult.success) {
                if (this.props.createDataObjectsUploadJobResult.code === "PERMISSION_DENIED") {
                    this.setState({
                        busy: false,
                        permissionDenied: true
                    });
                }
                else {
                    this.setState({
                        busy: false
                    });
                    if (this.props.createDataObjectsUploadJobResult.code === "JOB_ALREADY_RUNNING") {
                        showError("A job is already running. Upload cannot start until the existing bulk job completes.")
                    }
                    else {
                        showError("Could not able to create upload job.");
                    }
                }
            }
            else {
                this.setState({
                    busy: true,
                    busyMessage: "Job created. Please wait..."
                });
                showSuccess("Job created successfully.", () => {
                    this.goToJobs();
                });
            }
        }
    }

    createDataObjectsBulkUploadJob = (dataUploadOptions, file, fieldMappings) => {
        this.setState({
            busy: true,
            busyMessage: "Creating job. Please wait..."
        });
        this.props.createDataObjectsUploadJob(this.props.schemaModel.businessAreaId, this.props.schemaModel.schemaId, this.props.dataSet, dataUploadOptions, file, fieldMappings);
    }

    goToJobs = () => {
        this.props.history.push(AppPaths.TENANT_MONITOR_ITEM.replace(":tenant", this.props.tenant.toLowerCase()).replace(":monitorItem", "jobs"));
    }

    loadingComponentData = () => {
        return this.state.fetchingDataSet || this.state.fetchingDataSetPermission;
    }

    isBusy = () => {
        let isBusy = this.state.busy || this.loadingComponentData();
        return isBusy;
    }

    getNotFoundError = () => {
        if (!this.props.dataSet) {
            if (this.props.getDataSetByNameResult.success) {
                return {
                    title: "Dataset not found.",
                    description: "The dataset you are looking for does not exist."
                }
            }
            return {
                title: "Dataset could not be fetched.",
                description: "An error occurred while fetching dataset. Please contact support."
            }
        }
        return null;
    }

    getBulkUploadSourceFromHash = () => {
        if (this.props.history.location.hash) {
            let hashValue = decodeURIComponent(this.props.history.location.hash);
            let bulkUploadSource = hashValue.slice(1);
            return bulkUploadSource;
        }
        return null;
    }

    getDataObjectComponent = () => {
        let notFoundError = this.getNotFoundError();
        switch (this.props.action.toLowerCase()) {
            case "bulkupload":
                return !notFoundError ?
                    <DataObjectBulkUpload
                        action={this.props.action}
                        dataSet={this.props.dataSet}
                        schemaModel={this.props.schemaModel}
                        onCreateDataObjectsBulkUploadJob={this.createDataObjectsBulkUploadJob}
                        bulkUploadSource={this.getBulkUploadSourceFromHash()}>
                    </DataObjectBulkUpload>
                    :
                    <NotFoundError
                        title={notFoundError.title}
                        description={notFoundError.description}>
                    </NotFoundError>;
            case "history":
                return !notFoundError ?
                    (this.state.showHistory ?
                        <DataObjectAudit
                            businessAreaId={this.props.schemaModel.businessAreaId}
                            schemaId={this.props.schemaModel.schemaId}
                            dataSetId={this.props.dataSet.dataSetId}
                            dataObjectId={this.props.dataObjectId}
                            schemaFields={this.props.schemaModel.fields}>
                        </DataObjectAudit> : <></>)
                    :
                    <NotFoundError
                        title={notFoundError.title}
                        description={notFoundError.description}>
                    </NotFoundError>
            default:
                return <ProhibitedArea></ProhibitedArea>
        }
    }

    render() {
        if (this.state.permissionDenied) {
            return <ProhibitedArea></ProhibitedArea>;
        }
        return <div className="page-container">
            <LoadingOverlay
                busy={this.isBusy()}
                spinner
                message={this.state.busyMessage || "Please wait..."}>
            </LoadingOverlay>
            {!this.loadingComponentData() ? this.getDataObjectComponent() : <></>}
        </div>;
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        userBusinessAreas: state.schemaModels.userBusinessAreas,
        schemaModel: state.schemaModels.schemaModel,
        schemaPermission: state.schemaModels.permission,
        getUserDataSetPermissionResult: state.dataSets.getUserDataSetPermissionResult,
        dataSetpermission: state.dataSets.permission,
        getDataSetByNameResult: state.dataSets.getDataSetByNameResult,
        dataSet: state.dataSets.dataSet,
        createDataObjectsUploadJobResult: state.dataObjects.createDataObjectsUploadJobResult
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getUserDataSetPermission: () => dispatch(actions.getUserDataSetPermissionRequest()),
        getDataSetByName: (businessAreaId, schemaId, dataSetName) => dispatch(actions.getDataSetByNameRequest(businessAreaId, schemaId, dataSetName)),
        createDataObjectsUploadJob: (businessAreaId, schemaId, dataSet, dataUploadOptions, file, fieldMappings) => dispatch(actions.createDataObjectsUploadJobRequest(businessAreaId, schemaId, dataSet, dataUploadOptions, file, fieldMappings)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DataObject));