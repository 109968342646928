import React, { Component } from 'react';
import { Form, Input, Row, Col, Button, Space, Modal } from 'antd';
import { SchemaModelFieldList, SchemaModelFieldProperties, FullHeightContainerLayout } from 'components';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import _ from 'lodash';
import { Segment } from 'semantic-ui-react';
import fieldDataTypes from 'common/data/fieldDataTypes';
import moment from 'moment';
import { unflatten } from 'flat';
import flatten from 'flat';
import CypressTestIds from 'cypress/CypressTestIds';

// import { isValidLatitude, isValidLongitude, toDecimal } from 'geolib';

class ManageSchemaModel extends Component {

    constructor(props) {
        super(props);
        this.state = {
            readOnlyView: this.props.action === "view"
        }
        this.schemaModel = this.props.defaultValue || {
            name: '',
            description: '',
            fields: []
        };
        this.emptyObject = {};
        this.formRef = React.createRef();
    }

    componentWillMount() {

    }

    componentDidMount() {
        this.populateSchemaModel();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.schemaModel !== prevProps.schemaModel) {
            this.populateSchemaModel();
        }
    }

    populateSchemaModel = () => {
        if (this.props.schemaModel && this.formRef.current) {
            switch (this.props.action) {
                case "view":
                case "edit":
                    this.formRef.current.resetFields();
                    let fields = [];
                    for (let fieldItem of this.props.schemaModel.fields) {
                        let field = { ...fieldItem };
                        if (field.max && field.min) {
                            let format = null;
                            switch (field.dataType) {
                                case "datetime":
                                    format = `${_.replace(field.dateFormat, /\$/g, field.dateSeparator)} ${field.timeFormat}`;
                                    field.range = [
                                        moment(moment(field.min, "YYYY-MM-DDTHH:mm:ss"), format),
                                        moment(moment(field.max, "YYYY-MM-DDTHH:mm:ss"), format)
                                    ];

                                    delete field.min;
                                    delete field.max;
                                    break;
                                case "date":
                                    format = `${_.replace(field.dateFormat, /\$/g, field.dateSeparator)}`;
                                    field.range = [
                                        moment(moment(field.min, "YYYY-MM-DDTHH:mm:ss"), format),
                                        moment(moment(field.max, "YYYY-MM-DDTHH:mm:ss"), format)
                                    ];

                                    delete field.min;
                                    delete field.max;
                                    break;
                                case "time":
                                    format = field.timeFormat;
                                    field.range = [
                                        moment.utc(field.min),
                                        moment.utc(field.max),
                                    ];

                                    delete field.min;
                                    delete field.max;
                                    break;
                            }
                        }
                        fields.push(field);
                    }
                    this.formRef.current.setFieldsValue({ ...this.props.schemaModel, fields: fields });
                    this.schemaModel = this.formRef.current.getFieldsValue();
                    break;
            }
        }
    }

    onFormSubmit = (schemaModel) => {
        if (!this.isSaveAllowed()) {
            return;
        }

        if (this.props.action === "edit") {
            let updateSchemaModelData = {
                schemaModel: null,
                newFields: [],
                deletedFields: [],
                updatedFields: []
            };
            let originalSchemaModel = { ...this.props.schemaModel };
            let updatedSchemaModel = { ...schemaModel };

            let originalSchemaFieldDict = originalSchemaModel.fields.reduce((fieldObject, field) => {
                fieldObject[field.fieldId] = field;
                return fieldObject;
            }, {});

            let updatedSchemaFieldDict = {};
            let newFieldIndexMappings = {};
            let updatedSchemaFieldIndexes = {};
            for (let index = 0; index < updatedSchemaModel.fields.length; index++) {
                let field = { ...updatedSchemaModel.fields[index] };
                if (field.range) {
                    switch (field.dataType) {
                        case "datetime":
                        case "date":
                            field.min = field.range[0].format("YYYY-MM-DDTHH:mm:ss");
                            field.max = field.range[1].format("YYYY-MM-DDTHH:mm:ss");
                            delete field.range;
                            break;
                        case "time":
                            field.min = field.range[0].utc().valueOf();
                            field.max = field.range[1].utc().valueOf();
                            delete field.range;
                            break;
                    }
                }

                if (field.fieldId) {
                    updatedSchemaFieldDict[field.fieldId] = field;
                    updatedSchemaFieldIndexes[field.fieldId] = index;
                }
                else {
                    newFieldIndexMappings[updateSchemaModelData.newFields.length] = index;
                    updateSchemaModelData.newFields.push(field);
                }
            }

            delete originalSchemaModel.fields;
            delete updatedSchemaModel.fields;
            updatedSchemaModel = { ...originalSchemaModel, ...updatedSchemaModel };
            if (_.isEqual(originalSchemaModel, updatedSchemaModel) === false) {
                updateSchemaModelData.schemaModel = updatedSchemaModel;
            }

            let updatedSchemaFieldIndexMapping = {};
            for (let fieldId in originalSchemaFieldDict) {
                if (!updatedSchemaFieldDict[fieldId]) {
                    updateSchemaModelData.deletedFields.push(originalSchemaFieldDict[fieldId]);
                }
                else if (_.isEqual(originalSchemaFieldDict[fieldId], updatedSchemaFieldDict[fieldId]) === false) {
                    updatedSchemaFieldIndexMapping[updateSchemaModelData.updatedFields.length] = updatedSchemaFieldIndexes[fieldId];
                    updateSchemaModelData.updatedFields.push(updatedSchemaFieldDict[fieldId]);
                }
            }
            this.props.updateSchemaModel(this.props.schemaModel.schemaId, updateSchemaModelData, { newFieldIndexMappings, updatedSchemaFieldIndexMapping }, this.state.afterSaveCallback);
        }
        else {
            let newSchemaModel = { ...schemaModel };
            newSchemaModel.fields = [...newSchemaModel.fields];
            for (let index = 0; index < newSchemaModel.fields.length; index++) {
                let field = { ...newSchemaModel.fields[index] };
                newSchemaModel.fields[index] = field;
                if (field.range) {
                    switch (field.dataType) {
                        case "datetime":
                        case "date":
                            field.min = field.range[0].format("YYYY-MM-DDTHH:mm:ss");
                            field.max = field.range[1].format("YYYY-MM-DDTHH:mm:ss");
                            delete field.range;
                            break;
                        case "time":
                            field.min = field.range[0].utc().valueOf();
                            field.max = field.range[1].utc().valueOf();
                            delete field.range;
                            break;
                    }
                }
            }
            this.props.createSchemaModel(newSchemaModel, this.state.afterSaveCallback);
        }

        this.setState({
            afterSaveCallback: null
        })
    }

    getFormErrors = fieldName => {
        if (this.props.formErrors && this.props.formErrors[fieldName]) {
            return {
                help: this.props.formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return this.emptyObject;
    }

    getFieldRowLevelError = fieldIndex => {
        if (this.props.formErrors) {
            let formErrors = unflatten(this.props.formErrors);
            if (formErrors.fields && formErrors.fields[fieldIndex] && typeof formErrors.fields[fieldIndex] !== 'string') {
                return flatten(formErrors.fields[fieldIndex]);
            }
        }
        return null;
    }

    isReadOnlyView = () => {
        return (this.state.readOnlyView || !(this.props.permission.canEdit || this.props.permission.canAdd));
    }

    isSaveAllowed = () => {
        if (this.props.action === "edit") {
            return this.props.permission.canEdit;
        }
        else if (this.props.action === "create") {
            return this.props.permission.canAdd;
        }
        return false;
    }

    onFieldRowSelected = (fieldRow) => {
        if (this.state.selectedFieldRow && _.isEqual(this.state.selectedFieldRow, fieldRow)) {
            return;
        }
        if (fieldRow) {
            const dataTypeValue = this.formRef.current.getFieldValue(["fields", fieldRow.name, "dataType"]);
            this.setState({
                selectedFieldRow: fieldRow,
                selectedFieldDataType: dataTypeValue
            });
        }
    }

    onFieldRowDelete = (removeAction) => {
        this.setState({
            selectedFieldRow: null,
            selectedFieldDataType: null
        },
            () => {
                removeAction();
            });
    }

    onFieldDataTypeChange = (dataTypeValue, field) => {
        let min = 0;
        let max = 2000;
        if (dataTypeValue) {
            min = fieldDataTypes[dataTypeValue].min;
            max = fieldDataTypes[dataTypeValue].max;
        }

        let fields = this.formRef.current.getFieldValue(["fields"]);
        fields[field.name].max = max;
        fields[field.name].min = min;
        this.formRef.current.setFieldsValue({
            fields
        });
        this.setState({
            selectedFieldDataType: dataTypeValue
        });
    }

    onExportSchema = () => {
        if (this.props.schemaModel && this.props.schemaModel.schemaId) {
            let schemaModel = this.formRef.current.getFieldsValue();
            if (_.isEqual(schemaModel, this.schemaModel) === false) {
                this.setState({
                    showSaveConfirmationModal: true
                });
            }
            else {
                this.props.exportSchema(this.props.schemaModel.schemaId);
            }
        }
    }

    onSaveConfirmOk = () => {
        this.setState({
            showSaveConfirmationModal: false,
            afterSaveCallback: () => this.props.exportSchema(this.props.schemaModel.schemaId)
        }, () => {
            this.formRef.current.submit();
        });
    };

    onSaveConfirmCancel = () => {
        this.setState({
            showSaveConfirmationModal: false
        });
    };

    render() {
        return (
            <>
                <Modal
                    title="Save Schema"
                    centered={true}
                    visible={this.state.showSaveConfirmationModal}
                    onOk={this.onSaveConfirmOk}
                    onCancel={this.onSaveConfirmCancel}>
                    <p>There are unsaved changes. Do you want to save schema?</p>
                </Modal>
                <Form
                    ref={this.formRef}
                    name="basic"
                    initialValues={this.schemaModel}
                    onFinish={this.onFormSubmit}
                    onValuesChange={this.props.onValuesChanged}
                    style={{ height: "100%" }}>
                    <Row style={{ flexDirection: "row", flexGrow: 1, height: "100%" }} wrap={false}>
                        <Col span={18} style={{ display: "flex", flexDirection: "column", height: "100%", paddingRight: "5px" }}>
                            <FullHeightContainerLayout
                                showHeader={true}
                                showFooter={true}
                                header={<Row>
                                    <Col span={8} style={{ textAlign: 'left' }}>
                                        <Form.Item
                                            {...this.getFormErrors("name")}
                                            label="Schema Name"
                                            name={["name"]}
                                            validateFirst={true}
                                            rules={nameRules}>
                                            <Input readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.MANAGE_SCHEMA_MODEL_NAME_INPUT} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16} style={{ textAlign: 'left', paddingLeft: "5px" }}>
                                        <Form.Item
                                            label="Schema Description"
                                            name={["description"]}
                                            rules={descriptionRules}>
                                            <Input readOnly={this.isReadOnlyView()} data-testid={CypressTestIds.MANAGE_SCHEMA_MODEL_DESCRIPTION_INPUT}/>
                                        </Form.Item>
                                    </Col>
                                </Row>}
                                contentStyle={{ paddingBottom: "5px" }}
                                content={<Form.List name="fields">
                                    {(fields, { add, remove }) => {
                                        return <SchemaModelFieldList
                                            formRef={this.formRef}
                                            fields={fields}
                                            add={add}
                                            remove={remove}
                                            readOnly={this.isReadOnlyView()}
                                            onFieldRowSelected={this.onFieldRowSelected}
                                            onFieldRowDelete={this.onFieldRowDelete}
                                            onDataTypeChange={this.onFieldDataTypeChange}
                                            getFormErrors={this.getFormErrors}
                                            getFieldRowLevelError={this.getFieldRowLevelError} />;
                                    }}
                                </Form.List>}
                                footer={<Row>
                                    <Col span={24} className="footer-right-column">
                                        <Space>
                                            <Button data-testid={CypressTestIds.MANAGE_SCHEMA_MODEL_CANCEL_BUTTON} onClick={this.props.onCancel}>Cancel</Button>
                                            <Button data-testid={CypressTestIds.MANAGE_SCHEMA_MODEL_SAVE_AS_DRAFT_BUTTON} type="primary" htmlType="submit" disabled={this.isReadOnlyView() || !this.isSaveAllowed()}>Save as Draft</Button>
                                            <Button data-testid={CypressTestIds.MANAGE_SCHEMA_MODEL_SAVE_PUBLISH_BUTTON} type="primary" disabled={true}>Save & Publish</Button>
                                            <Button data-testid={CypressTestIds.MANAGE_SCHEMA_MODEL_EXPORT_BUTTON} type="primary" onClick={this.onExportSchema}>Export</Button>
                                        </Space>
                                    </Col>
                                </Row>
                                }>
                            </FullHeightContainerLayout>
                        </Col>
                        <Col span={6} style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                            <Segment className="field-properties-container">
                                <SchemaModelFieldProperties
                                    selectedFieldRow={this.state.selectedFieldRow}
                                    formRef={this.formRef}
                                    readOnly={this.isReadOnlyView()}
                                    fieldDataType={this.state.selectedFieldDataType}
                                    getFormErrors={this.getFormErrors}
                                    getBusinessAreaDataForBusinessAreaResult={this.props.getBusinessAreaDataForBusinessAreaResult}
                                    businessAreaDataList={this.props.businessAreaDataList}>
                                </SchemaModelFieldProperties>
                            </Segment>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

export default ManageSchemaModel