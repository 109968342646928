export const GET_DATA_SYNDICATION_SOURCE_LIST_REQUEST = "GET_DATA_SYNDICATION_SOURCE_LIST_REQUEST";
export const GET_DATA_SYNDICATION_SOURCE_LIST_SUCCESS = "GET_DATA_SYNDICATION_SOURCE_LIST_SUCCESS";
export const GET_DATA_SYNDICATION_SOURCE_LIST_FAILURE = "GET_DATA_SYNDICATION_SOURCE_LIST_FAILURE";

export const GET_DATA_SYNDICATION_SOURCE_REQUEST = "GET_DATA_SYNDICATION_SOURCE_REQUEST";
export const GET_DATA_SYNDICATION_SOURCE_SUCCESS = "GET_DATA_SYNDICATION_SOURCE_SUCCESS";
export const GET_DATA_SYNDICATION_SOURCE_FAILURE = "GET_DATA_SYNDICATION_SOURCE_FAILURE";

export const CREATE_DATA_SYNDICATION_SOURCE_REQUEST = "CREATE_DATA_SYNDICATION_SOURCE_REQUEST";
export const CREATE_DATA_SYNDICATION_SOURCE_SUCCESS = "CREATE_DATA_SYNDICATION_SOURCE_SUCCESS";
export const CREATE_DATA_SYNDICATION_SOURCE_FAILURE = "CREATE_DATA_SYNDICATION_SOURCE_FAILURE";

export const UPDATE_DATA_SYNDICATION_SOURCE_REQUEST = "UPDATE_DATA_SYNDICATION_SOURCE_REQUEST";
export const UPDATE_DATA_SYNDICATION_SOURCE_SUCCESS = "UPDATE_DATA_SYNDICATION_SOURCE_SUCCESS";
export const UPDATE_DATA_SYNDICATION_SOURCE_FAILURE = "UPDATE_DATA_SYNDICATION_SOURCE_FAILURE";

export const DELETE_DATA_SYNDICATION_SOURCE_REQUEST = "DELETE_DATA_SYNDICATION_SOURCE_REQUEST";
export const DELETE_DATA_SYNDICATION_SOURCE_SUCCESS = "DELETE_DATA_SYNDICATION_SOURCE_SUCCESS";
export const DELETE_DATA_SYNDICATION_SOURCE_FAILURE = "DELETE_DATA_SYNDICATION_SOURCE_FAILURE";

export const getDataSyndicationSourceListRequest = () => ({
    type: GET_DATA_SYNDICATION_SOURCE_LIST_REQUEST
})

export const getDataSyndicationSourceListSuccess = (result) => ({
    type: GET_DATA_SYNDICATION_SOURCE_LIST_SUCCESS,
    payload: {
        result
    }
})

export const getDataSyndicationSourceListFailure = (error) => ({
    type: GET_DATA_SYNDICATION_SOURCE_LIST_FAILURE,
    payload: {
        error
    }
})

export const getDataSyndicationSourceRequest = (sourceId) => ({
    type: GET_DATA_SYNDICATION_SOURCE_REQUEST,
    params: {
        sourceId
    }
})

export const getDataSyndicationSourceSuccess = (sourceId, source) => ({
    type: GET_DATA_SYNDICATION_SOURCE_SUCCESS,
    payload: {
        sourceId,
        source
    }
})

export const getDataSyndicationSourceFailure = (error) => ({
    type: GET_DATA_SYNDICATION_SOURCE_FAILURE,
    payload: {
        error
    }
})

export const createDataSyndicationSourceRequest = (source) => ({
    type: CREATE_DATA_SYNDICATION_SOURCE_REQUEST,
    params: {
        source
    }
})

export const createDataSyndicationSourceSuccess = (source) => ({
    type: CREATE_DATA_SYNDICATION_SOURCE_SUCCESS,
    payload: {
        source
    }
})

export const createDataSyndicationSourceFailure = (error) => ({
    type: CREATE_DATA_SYNDICATION_SOURCE_FAILURE,
    payload: {
        error
    }
})

export const updateDataSyndicationSourceRequest = (sourceId, updatedSource) => ({
    type: UPDATE_DATA_SYNDICATION_SOURCE_REQUEST,
    params: {
        sourceId,
        updatedSource
    }
})

export const updateDataSyndicationSourceSuccess = (sourceId, source) => ({
    type: UPDATE_DATA_SYNDICATION_SOURCE_SUCCESS,
    payload: {
        sourceId,
        source
    }
})

export const updateDataSyndicationSourceFailure = (error) => ({
    type: UPDATE_DATA_SYNDICATION_SOURCE_FAILURE,
    payload: {
        error
    }
})

export const deleteDataSyndicationSourceRequest = (sourceId) => ({
    type: DELETE_DATA_SYNDICATION_SOURCE_REQUEST,
    params: {
        sourceId
    }
})

export const deleteDataSyndicationSourceSuccess = (sourceId) => ({
    type: DELETE_DATA_SYNDICATION_SOURCE_SUCCESS,
    payload: {
        sourceId
    }
})

export const deleteDataSyndicationSourceFailure = (error) => ({
    type: DELETE_DATA_SYNDICATION_SOURCE_FAILURE,
    payload: {
        error
    }
})
