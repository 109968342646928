import React, { useEffect, useState, useCallback } from 'react';
import { Form, Input, Row, Col, Button, Space, InputNumber, Switch, Select, Table, DatePicker, Checkbox, Typography } from 'antd';
import { FullHeightContainerLayout, CopyInput } from "components";
import { diff } from 'deep-object-diff';
import _ from 'lodash';
import { nameRules, descriptionRules } from 'common/FormValidationRules';
import { ScheduleRate } from 'common/enums';
import { BusinessAreaSelectFormItem, SchemaModelFormItem, DataSetSelectFormItem } from 'containers';
import { usePrevious } from 'common/customHooks';

const { Option } = Select;

const DATASET_DEFAULT_ITEM = {
    dataSetId: "ALL_DATASETS",
    dataSetName: "All Datasets under this schema"
}

const EMPTY_OBJECT = {};

function ManageDataSyndicationSource({ action, syndicationSourceVaue, formErrors, onSave, onCancel, permission, readOnlyView, onValuesChanged }) {
    const [selectedBusinessAreaId, setSelectedBusinessAreaId] = useState((syndicationSourceVaue && syndicationSourceVaue.businessAreaId) || null);
    const [selectedSchemaId, setSelectedSchemaId] = useState((syndicationSourceVaue && syndicationSourceVaue.schemaId) || null);
    const [shareDataSetChecked, setShareDataSetChecked] = useState((syndicationSourceVaue && syndicationSourceVaue.enabled) || false);
    const [form] = Form.useForm();

    const onBusinessAreaChange = useCallback((businessAreaId, businessArea) => {
        setSelectedBusinessAreaId(businessAreaId);
        form.setFieldsValue({
            businessAreaDescription: businessArea.description,
            schemaId: null,
            dataSetId: DATASET_DEFAULT_ITEM.dataSetId
        });
    }, [form]);

    const onSchemaModelChange = useCallback((schemaId, schemaModel) => {
        setSelectedSchemaId(schemaId);
        form.setFieldsValue({
            schemaDescription: schemaModel.description,
            dataSetId: DATASET_DEFAULT_ITEM.dataSetId
        });
    }, [form]);

    const onDataSetChange = useCallback((dataSetId, dataSet) => {

    }, []);

    const onShareDataSetChange = useCallback((e) => {
        setShareDataSetChecked(e.target.checked);
    }, []);

    const getFormErrors = useCallback(fieldName => {
        if (formErrors && formErrors[fieldName]) {
            return {
                help: formErrors[fieldName],
                validateStatus: "error"
            }
        }
        return EMPTY_OBJECT;
    }, [formErrors]);

    const onFormSubmit = useCallback((formData) => {
        let syndicationSource = { ...formData };
        if (action === "create") {
            onSave(syndicationSource);
        }
        else {
            let diffObject = diff(syndicationSourceVaue, syndicationSource);
            let updatedObject = {};
            for (let key in diffObject) {
                if (diffObject[key] !== undefined) {
                    updatedObject[key] = syndicationSource[key];
                }
            }
            if (_.isEmpty(updatedObject) === false) {
                updatedObject.version = syndicationSourceVaue.version;
            }

            onSave(syndicationSourceVaue.sourceId, updatedObject);
        }
    }, [action, syndicationSourceVaue, onSave]);

    const isReadOnlyView = useCallback(() => {
        return (readOnlyView || !(permission.canEdit || permission.canAdd));
    }, [readOnlyView, permission]);

    const isSaveAllowed = useCallback(() => {
        if (action === "edit") {
            return permission.canEdit;
        }
        else if (action === "create") {
            return permission.canAdd;
        }
        return false;
    }, [action, permission]);

    return <Form
        name="basic"
        form={form}
        layout="vertical"
        labelAlign="right"
        labelCol={{ md: { span: 24 }, lg: { span: 6 }, xl: { span: 5 }, xxl: { span: 3 } }}
        style={{ height: "100%" }}
        initialValues={syndicationSourceVaue || {
            dataSetId: DATASET_DEFAULT_ITEM.dataSetId
        }}
        onFinish={onFormSubmit}
        onValuesChange={onValuesChanged}
        disabled={isReadOnlyView()}>
        <FullHeightContainerLayout
            showFooter={true}
            content={
                <>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                name="name"
                                label="Name"
                                rules={nameRules}
                                {...getFormErrors("name")}>
                                <Input />
                            </Form.Item>

                        </Col>
                        <Col span={12}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                name="description"
                                label="Purpose"
                                rules={descriptionRules}
                                {...getFormErrors("description")}>
                                <Input />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <BusinessAreaSelectFormItem
                                formItemProps={{
                                    labelCol: { span: 24 },
                                    label: "Business Area",
                                    name: "businessAreaId",
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    ...getFormErrors("businessAreaId")
                                }}
                                disabled={isReadOnlyView()}
                                onChange={onBusinessAreaChange}>
                            </BusinessAreaSelectFormItem>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                name="businessAreaDescription"
                                label="Business Area Description">
                                <Input readOnly={true} />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <SchemaModelFormItem
                                businessAreaId={selectedBusinessAreaId}
                                formItemProps={{
                                    labelCol: { span: 24 },
                                    label: "Schema",
                                    name: ["schemaId"],
                                    rules: [
                                        {
                                            required: true,
                                        },
                                    ],
                                    ...getFormErrors("schemaId")
                                }}
                                disabled={isReadOnlyView()}
                                onChange={onSchemaModelChange}>
                            </SchemaModelFormItem>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                labelCol={{ span: 24 }}
                                name="schemaDescription"
                                label="Schema Description">
                                <Input readOnly={true} />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Row>
                                <Col span={4}>
                                    <Form.Item
                                        name="shareDataset"
                                        valuePropName="checked"
                                        {...getFormErrors("shareDataset")}>
                                        <Checkbox onChange={onShareDataSetChange}>Share Dataset</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={20}>
                                    <DataSetSelectFormItem
                                        businessAreaId={selectedBusinessAreaId}
                                        schemaId={selectedSchemaId}
                                        formItemProps={{
                                            name: "dataSetId",
                                            ...getFormErrors("dataSetId")
                                        }}
                                        disabled={isReadOnlyView() || !shareDataSetChecked}
                                        onChange={onDataSetChange}
                                        defaultItem={DATASET_DEFAULT_ITEM}>
                                    </DataSetSelectFormItem>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="Valid From"
                                        name="validFrom"
                                        {...getFormErrors("validFrom")}>
                                        <DatePicker showTime style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="Valid Until"
                                        name="validUntil"
                                        {...getFormErrors("validUntil")}>
                                        <DatePicker showTime style={{ width: "100%" }} />
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="Rate Value"
                                        name="rateValue"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        {...getFormErrors("rateValue")}>
                                        <InputNumber min={1} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        labelCol={{ span: 24 }}
                                        label="Rate Unit"
                                        name="rateUnit"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                        {...getFormErrors("rateUnit")}>
                                        <Select>
                                            {Object.keys(ScheduleRate).map(rate => <Option key={rate} value={rate}>{ScheduleRate[rate]}</Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="enabled" label="Enabled" valuePropName="checked" {...getFormErrors("enabled")}>
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }
            footer={
                <Row>
                    <Col span={24} className="footer-right-column">
                        <Space>
                            <Button onClick={onCancel} disabled={false}>Cancel</Button>
                            <Button type="primary" htmlType="submit" disabled={isReadOnlyView() || !isSaveAllowed()}>Save</Button>
                        </Space>
                    </Col>
                </Row>
            }>
        </FullHeightContainerLayout>
    </Form >
}

export default ManageDataSyndicationSource